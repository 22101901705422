.img-container {
	margin-top: 10px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.img-container-items {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.explore_select .ant-select {
	font-size: 14px;
}

.option-explore .ant-select-item-option-content {
	padding: 10px;
}

.option-explore .ant-select-item-option-content:hover {
	background-color: #ffdfd1 !important;
}

.img-container-collection {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 94%;
}

.img-container-collection-item {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 103%;
}
.img-container-collection-not-connect {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 94%;
}

.img-container .box {
	position: relative;
	width: 16%;
	height: 355px;
	overflow: hidden;
	transition: 0.5s;
	&:hover .delay-explore-item1 {
		animation: waves 2.5s linear;
		animation-delay: .1s;
	}

	&:hover .delay-explore-item2 {
		animation: waves 2.5s linear .7s infinite;
	}

	&:hover .delay-explore-item3 {
		animation: waves 2.5s linear 1.3s infinite;
	}

	&:hover .delay-explore-item4 {
		animation: waves 2.5s linear 1.9s infinite;
	}
}

.img-container .box .imgBx {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.img-container .box .imgBx img {
	// position: absolute;
	top: 0;
	left: 0;
	width: inherit;
	min-height: 355px;
	object-fit: contain;
	height: 300px;
	width: 300px;
}
.img-container .box .content {
	position: absolute;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 5px;
	align-items: center;
	font-size: 15px;
}
.content-hovring {
	align-self: center;
}

.top-left {
	position: absolute;
	display: flex;
	align-items: center;
	top: 20px;
	left: 15px;
	height: 30px;
	transition: 0.5s;
	transform: translateX(-200px);
}
.image-explore-avatar {
	border-radius: 50%;
	width: 50px;
	cursor: pointer;
}
.nft-details {
	display: flex;
	flex-direction: column;
	height: 50px;
	margin-left: 10px;
	width: 200px;
}
.nft-details span {
	color: white;
	height: 17px;
	font-size: 14px;
	text-shadow: -1px 1px #8e8a8a;
}
.img-container .box:hover .content .topCollection .top-left {
	transform: translateX(0);
	transition-delay: 0.4s;
}

.float-explore-decline {
	padding: 1px 16px;
	border-radius: 5px !important;
	font-size: 13px;
	background-color: #1cbf51;
	border-color: #1cbf51;
	color: white;
	margin-left: 100px;
}
.float-explore-approved {
	padding: 1px 16px;
	border-radius: 5px !important;
	font-size: 13px;
	background-color: #fd4040;
	border-color: #fd4040;
	color: white;
	margin-left: -4px;
}

.auction-infos-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	border-radius: 5px;
	margin-bottom: 10px;
}

.auction-info-box-holder {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.place-bid-button {
	width: 100%;
	height: 30px;
	border-radius: 5px !important;
	font-size: 13px;
	background-color: @primary-color;
	border-color: @primary-color;
	color: white;
}

.float-explore-auction {
	width: 275px;
	height: 70px;
	position: absolute;
	top: -50px;
	right: -269px;
	padding: 17px;
	padding-top: 27px;
	line-height: initial;
	border-radius: 15px !important;
	color: black;
}
.text-min-auction {
	font-size: 12px !important;
}

.img-container .box:hover .content .likeButton {
	transform: translateX(0);
	transition-delay: 0.4s;
	z-index: 1;
}
.img-container .box:hover .content .select-image-explore {
	transform: translateX(0);
	transition-delay: 0.4s;
	z-index: 1;
}

.img-container .box .content .auctionDetails {
	width: 100%;
	transition: 00.5s;
	transform: translateY(400px);
	margin: 0 10px;
}
.img-container .box .content .auctionDetails div {
	display: flex;
	justify-content: space-around;
	z-index: 5;
}

.img-container .box:hover .content .auctionDetails {
	transform: translateY(180px);
	transition-delay: 0.4s;
}

.img-container .box {
	width: 10%;
}

.likeButton .like-button-container {
	width: 40px !important;
}
.likeButton .empty-container {
	width: 40px !important;
	height: 40px;
}
.likeButton .filled-heart .empty-heart {
	width: 20px !important;
}
.likeButton .filled-heart {
	width: 40px !important;
}
.img-container .box .content .likeButton {
	position: absolute;
	top: 20px;
	right: -235px;

	transition: 0.5s;
	transform: translateX(200px);
	cursor: pointer;
}

.select-image-explore {
	position: absolute;
	top: 11px;
	right: -40%;
	width: 30px !important;
	transition: 0.5s;
	transform: translateX(200px);
	cursor: pointer;
}
.text-min-auction {
	font-size: 10px !important;
}
.float-explore-auction {
	width: 240px;
	height: 65px;
	position: absolute;
	top: -50px;
	right: -250px;
	//padding: 17px;
	padding-top: 27px;
	line-height: initial;
	border-radius: 18px !important;
	color: black;
	font-size: 12px;
}
.image-explore-avatar {
	border-radius: 50%;
	width: 50px;
	cursor: pointer;
}

.preview-bloc-image-details {
	display: flex;
	justify-content: space-around;
	align-Items: center;
	span {
		margin-left: 5px;
	}
}

.play-block-icon-explore-item {
	position: absolute;
	top: 0% !important;
	left: 40% !important;
	cursor: pointer;
	z-index: 1;
	height: 57px !important;
	width: 57px !important;
}

.circle-explore-item {
	display: block;
	height: 53px;
	width: 53px;
	border-radius: 50%;
	background: #ffdfd1;
	margin: 10px;
	transition: 5s ease;
	position: absolute;
	top: 37%;
	left: 37%;
}

.icon-3d-rotate {
	position: absolute;
	top: 20%;
	right: 3%;
	height: 45px;
}

@media only screen and (max-width: 3000px) {
	.float-explore-decline {
		margin-left: 80px;
	}
	.img-container .box .content .likeButton {
		right: -210px;
	}
	.float-explore-auction {
		right: -240px;
	}
}
@media only screen and (max-width: 2700px) {
	.float-explore-decline {
		margin-left: 160px;
	}
	.img-container .box {
		width: 15%;
	}
	.img-container .box .content .likeButton {
		right: -280px;
	}
	.float-explore-auction {
		width: 280px;
		right: -290px;
	}
	.title-float-auction {
		font-size: 12px;
		font-weight: bolder;
	}
}

@media screen and (min-width: 2800px) {
	.img-container .box .imgBx img {
		height: 10vw;
		width: 10vw;
	}
}
@media screen and (max-width: 2790px) {
	.img-container .box .imgBx img {
		height: 15vw;
		width: 15vw;
	}
}
@media only screen and (max-width: 2400px) {
	.float-explore-decline {
		margin-left: 140px;
	}
	.img-container .box {
		width: 15%;
	}

	.img-container .box .content .likeButton {
		position: absolute;
		top: 20px;
		right: -275px;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.select-image-explore {
		position: absolute;
		top: 11px;
		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.float-explore-auction {
		width: 280px;
		height: 70px;
		position: absolute;
		top: -50px;
		right: -285px;
		padding: 17px;
		padding-top: 27px;
		line-height: initial;
		border-radius: 15px !important;
		color: black;
	}
}
@media only screen and (max-width: 2100px) {
	.float-explore-decline {
		margin-left: 100px;
	}
	.img-container .box {
		width: 15%;
	}
	.img-container .box {
		height: 300px;
	}
	.img-container .box .imgBx img {
		min-height: 300px;
	}
	.img-container .box .content .likeButton {
		position: absolute;
		top: 20px;
		right: -230px;
		//width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.select-image-explore {
		position: absolute;
		top: 11px;
		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.float-explore-auction {
		width: 270px;
		height: 60px;
		position: absolute;
		top: -50px;
		right: -260px;
		padding: 17px;
		padding-top: 27px;
		line-height: initial;
		border-radius: 15px !important;
		color: black;
	}
}
@media only screen and (max-width: 1970px) {
	.float-explore-decline {
		margin-left: 80px;
	}
	.img-container .box {
		width: 15%;
	}
	.nft-details {
		margin-top: 7px;
		font-size: 13px;
	}
	.img-container .box .content .likeButton {
		position: absolute;
		top: 13px;
		right: -210px;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.image-explore-avatar {
		width: 43px;
	}
	.select-image-explore {
		position: absolute;
		top: 11px;

		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.float-explore-auction .title-float-auction {
		font-size: 12px;
		font-weight: bolder;
	}
	.float-explore-auction .text-min-auction {
		font-size: 10px !important;
	}
	.float-explore-auction {
		width: 240px;
		height: 65px;
		position: absolute;
		top: -50px;
		right: -230px;
		font-size: 12px;
		//padding: 1px;
		padding-top: 25px;
		line-height: initial;
		border-radius: 17px !important;
		color: black;
	}
	.img-container .box .imgBx img {
		height: 300px;
		width: 300px;
	}
}

@media only screen and (max-width: 1800px) {
	.float-explore-decline {
		margin-left: 50px;
	}
	.img-container .box .content .likeButton {
		right: -185px;
	}

	.float-explore-auction {
		right: -220px;
		width: 230px;
	}
}
@media only screen and (max-width: 1700px) {
	.float-explore-decline {
		margin-left: 70px;
	}
	.img-container .box {
		width: 18%;
	}
	.img-container .box .content .likeButton {
		position: absolute;
		top: 15px;
		right: -210px;
		// width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.select-image-explore {
		position: absolute;
		top: 11px;
		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.float-explore-auction {
		width: 234px;
		height: 60px;
		position: absolute;
		top: -40px;
		right: -235px;
		font-size: 11px;
		padding: 13px;
		padding-top: 25px;
		line-height: initial;
		border-radius: 15px !important;
		background-color: transparent;
		border-color: transparent;
		color: black;
	}
}
@media only screen and (max-width: 1500px) {
	.float-explore-decline {
		margin-left: 60px;
	}
	.img-container .box {
		width: 19%;
	}
	.image-explore-avatar {
		margin-left: -5px;
		width: 45px;
	}
	.nft-details {
		margin-top: 10px;
	}
	.img-container .box .content .likeButton {
		position: absolute;
		top: 15px;
		right: -185px;
		//width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.select-image-explore {
		position: absolute;
		top: 11px;
		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.text-min-auction {
		font-size: 10px !important;
	}

	.float-explore-auction {
		width: 240px;
		height: 60px;
		position: absolute;
		top: -40px;
		right: -227px;
		font-size: 9px;
		//padding: 5px;
		padding-top: 17px;
		line-height: initial;
		border-radius: 15px !important;
		color: black;
	}
}
@media only screen and (max-width: 1400px) {
	.float-explore-decline {
		margin-left: 80px;
	}
	.img-container .box {
		width: 22%;
	}
	.nft-details {
		margin-top: 9px;
	}
	.img-container .box .content .likeButton {
		right: -215px;
	}
	.float-explore-auction {
		right: -240px;
	}
}

@media only screen and (max-width: 1300px) {
	.float-explore-decline {
		margin-left: 60px;
	}
	.img-container .box {
		width: 22%;
	}

	.img-container .box .content .likeButton {
		position: absolute;
		top: 15px;
		right: -195px;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.select-image-explore {
		position: absolute;
		top: 11px;

		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.text-min-auction {
		font-size: 12px !important;
	}
	.float-explore-auction {
		width: 230px;
		height: 50px;
		position: absolute;
		top: -30px;
		right: -225px;
		font-size: 9px;
		padding: 10px;
		padding-top: 20px;
		line-height: initial;
		border-radius: 12px !important;
		color: black;
	}
}

.image-container-self-explore .box {
	width: 50% !important;
}
.image-container-self-explore {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media only screen and (max-width: 1100px) {
	.float-explore-decline {
		margin-left: 80px;
	}
	.img-container .box {
		width: 28%;
	}

	.img-container .box .content .likeButton {
		position: absolute;
		top: 12px;
		right: -200px;
		// width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.select-image-explore {
		position: absolute;
		top: 11px;

		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}

	.float-explore-auction {
		width: 230px;
		height: 50px;
		position: absolute;
		top: -30px;
		right: -225px;
		font-size: 9px !important;
		padding: 7px;
		padding-top: 12px;
		line-height: initial;
		border-radius: 10px !important;
		color: black;
	}
}

@media only screen and (max-width: 900px) {
	.float-explore-decline {
		margin-left: 40px;
	}
	.img-container .box {
		width: 30%;
	}

	.img-container .box .content .likeButton {
		position: absolute;
		top: 20px;
		right: -150px;
		// width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.select-image-explore {
		position: absolute;
		top: 11px;

		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.text-min-auction {
		font-size: 10px !important;
	}
	.float-explore-auction {
		width: 200px;
		height: 50px;
		position: absolute;
		top: -25px;
		right: -185px;
		font-size: 9px;
		padding: 7px;
		padding-top: 10px;
		line-height: initial;
		border-radius: 10px !important;
		color: black;
	}
}

@media only screen and (max-width: 980px) {
	.float-explore-decline {
		margin-left: 40px;
	}
	.img-container .box {
		width: 30%;
	}
	.float-explore-auction {
		width: 210px;
		right: -200px;
	}
	.img-container .box .content .likeButton {
		top: 20px;
		right: -170px;
	}
}

@media only screen and (max-width: 800px) {
	.float-explore-decline {
		margin-left: 30px;
	}
	.img-container .box {
		width: 31%;
	}
	.float-explore-auction {
		width: 210px;
		right: -200px;
	}
	.img-container .box .content .likeButton {
		top: 20px;
		right: -170px;
	}
}

@media only screen and (max-width: 780px) {
	.float-explore-decline {
		margin-left: 70px;
	}
	.img-container .box {
		width: 38%;
	}
	.float-explore-auction {
		width: 240px;
		right: -230px;
		padding-top: 15px;
		border-radius: 25px;
	}
	.img-container .box .content .likeButton {
		top: 20px;
		right: -200px;
	}
	.select-image-explore {
		position: absolute;
		top: 11px;

		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
}
@media only screen and (max-width: 700px) {
	.float-explore-decline {
		margin-left: 30px;
	}
}
@media only screen and (max-width: 600px) {
	.img-container .box {
		width: 45%;
	}
	.img-container .box .content .likeButton {
		position: absolute;
		top: 20px;
		right: -180px;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}

	.select-image-explore {
		position: absolute;
		top: 11px;

		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.text-min-auction {
		font-size: 7px !important;
	}
	.float-explore-auction {
		width: 210px;
		height: 50px;
		position: absolute;
		top: -25px;
		right: -210px;
		font-size: 9px;
		padding: 7px;
		padding-top: 10px;
		line-height: initial;
		border-radius: 10px !important;
		color: black;
	}
}
@media only screen and (max-width: 500px) {
	.float-explore-decline {
		margin-left: 150px;
	}
	.img-container .box {
		width: 80%;
	}
	.img-container .box {
		height: 395px;
	}
	.img-container .box .imgBx img {
		min-height: 395px;
	}
	.img-container .box .content {
		padding: 40px;
	}
	.img-container .box .content .likeButton {
		position: absolute;
		top: 20px;
		right: -300px;
		// width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}

	.select-image-explore {
		position: absolute;
		top: 11px;
		right: 0;
		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.text-min-auction {
		font-size: 13px !important;
	}
	.float-explore-auction {
		width: 300px;
		height: 60px;
		position: absolute;
		top: -40px;
		right: -315px;
		font-size: 9px;
		padding: 7px;
		padding-top: 10px;
		line-height: initial;
		border-radius: 10px !important;
		color: black;
	}
}

@media only screen and (max-width: 450px) {
	.float-explore-decline {
		margin-left: 100px;
	}
	.img-container .box {
		width: 80%;
	}
	.img-container .box .content .likeButton {
		position: absolute;
		top: 15px;
		right: -240px;
		width: 50px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}

	.select-image-explore {
		position: absolute;
		top: 11px;
		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.text-min-auction {
		font-size: 7px !important;
	}
	.float-explore-auction {
		width: 250px;
		height: 60px;
		position: absolute;
		top: -35px;
		right: -255px;
		font-size: 13px;
		padding: 10px;
		padding-top: 13px;
		line-height: initial;
		border-radius: 15px !important;
		color: black;
	}
}
@media only screen and (max-width: 400px) {
	.float-explore-decline {
		margin-left: 80px;
	}
	.img-container .box {
		width: 85%;
	}
	.img-container .box .content .likeButton {
		position: absolute;
		top: 20px;
		right: -200px;
		width: 50px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}

	.select-image-explore {
		position: absolute;
		top: 11px;
		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.text-min-auction {
		font-size: 7px !important;
	}
	.float-explore-auction {
		width: 290px;
		height: 60px;
		position: absolute;
		top: -35px;
		right: -295px;
		font-size: 13px;
		padding: 9px;
		padding-top: 9px;
		line-height: initial;
		border-radius: 10px !important;
		color: black;
	}
}
@media only screen and (max-width: 399px) {
	.float-explore-decline {
		margin-left: 70px;
	}
	.img-container .box {
		width: 80%;
	}
	.img-container .box .content .likeButton {
		position: absolute;
		top: 20px;
		right: -205px;
		width: 50px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}

	.select-image-explore {
		position: absolute;
		top: 11px;
		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.text-min-auction {
		font-size: 7px !important;
	}
	.float-explore-auction {
		width: 240px;
		height: 60px;
		position: absolute;
		top: -35px;
		right: -230px;
		font-size: 11px;
		padding: 9px;
		padding-top: 9px;
		line-height: initial;
		border-radius: 10px !important;
		color: black;
	}
}
@media only screen and (max-width: 350px) {
	.float-explore-decline {
		margin-left: 40px;
	}
	.img-container .box {
		width: 80%;
	}
	.img-container .box .content .likeButton {
		position: absolute;
		top: 20px;
		right: -180px;
		width: 50px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}

	.select-image-explore {
		position: absolute;
		top: 11px;
		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.text-min-auction {
		font-size: 7px !important;
	}
	.float-explore-auction {
		width: 210px;
		height: 60px;
		position: absolute;
		top: -35px;
		right: -195px;
		font-size: 11px;
		padding: 9px;
		padding-top: 9px;
		line-height: initial;
		border-radius: 10px !important;
		color: black;
	}
}

@media only screen and (max-width: 290px) {
	.float-explore-decline {
		margin-left: 10px;
	}
	.img-container .box .content {
		padding: 15x;
	}
	.img-container .box {
		width: 99%;
	}
	.img-container .box .content .likeButton {
		position: absolute;
		top: 20px;
		right: -150px;
		width: 50px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}

	.select-image-explore {
		position: absolute;
		top: 11px;
		width: 30px !important;
		transition: 0.5s;
		transform: translateX(200px);
		cursor: pointer;
	}
	.text-min-auction {
		font-size: 7px !important;
	}
	.float-explore-auction {
		width: 210px;
		height: 60px;
		position: absolute;
		top: -35px;
		right: -185px;
		font-size: 11px;
		padding: 9px;
		padding-top: 9px;
		line-height: initial;
		border-radius: 10px !important;
		color: black;
	}
}
.spinner {
	margin: 100px auto 0;
	width: 70px;
	text-align: center;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: @primary-color;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
	background-color: @primary-color;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
	background-color: @primary-color;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;