body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
	font-family: 'BoldFont';
	src: local('BoldFont'), url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'RegularFont';
	src: local('RegularFont'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

h1 {
	font-size: calc(1.5rem + 1.4vw);
}

h2 {
	font-size: calc(1.1rem + 1vw);
}

h3 {
	font-size: calc(0.9rem + 0.7vw);
}

h4 {
	font-size: calc(0.7rem + 0.6vw);
}

h5 {
	font-size: calc(0.6rem + 0.4vw);
}

h6 {
	font-size: calc(0.5rem + 0.4vw);
}
