// body.light h1, body.light h2, body.light h3, body.light h4, body.light h5, body.light h6 {
//   color: #000000;
// }
.navbar-container {
	height: 100px;
	padding: 0px 4vw 0px 3vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10;
}
.msg-notif-navbar {
	height: 50px;
	width: 50px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2px;
	background-color: @primary-color;
}
.icon-notif-msg {
	height: 23px;
}

.icons-navbar-msg {
	margin-right: 5px;
	// margin-left: -8px;
	display: flex;
}

.main-body {
	margin-top: 100px;
}

.navbar-first-part {
	display: flex;
	align-items: center;
	width: fit-content;
	justify-content: flex-start;
	margin-left: 40px;
}

.logo-img {
	cursor: pointer;
}

.navbar-second-part {
	display: flex;
	align-items: center;
	width: 54%;

	justify-content: flex-end;
}
@media (width: 1900px) {
	.navbar-second-part {
		display: flex;
		align-items: center;
		//width: 54%;
		width: 56%;
	}
}
@media (width: 1400px) {
	.navbar-second-part {
		display: flex;
		align-items: center;
		//width: 54%;
		width: 65%;
	}
}
@media (width: 1500px) {
	.navbar-second-part {
		display: flex;
		align-items: center;
		//width: 54%;
		width: 65%;
	}
}

@media (width: 1300px) {
	.navbar-second-part {
		display: flex;
		align-items: center;
		//width: 54%;
		width: 65%;
	}
}
.button-holder {
	// margin: 0 1.5%;
	width: fit-content;
	margin: 0 5px;
	//  width: 8vw;
}

.nav-search-container {
	width: 30vw;
	.ant-input-prefix {
		margin-right: 0px;
	}
}

.floatingNav {
	border-radius: 2px;
	box-shadow: 0px 1px 10px rgb(201, 201, 201);
}

//////////////////// Switch //////////////////

.switch-button-container {
	margin: 0 1.5%;
	width: fit-content;
}

.switch-button {
	border-radius: 15px;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	cursor: pointer;
}

.switch-button-white {
	background-color: #f2f2f2;
	border: 2px solid #000000;
}

.switch-button-black {
	background-color: #000000;
	border: 2px solid #ffffff;
}

.switch-icon {
	width: 20px;
}

/////////////////// Menu //////////////////////////

.custom-menu {
	// margin-left: -40px;
	display: flex;
	align-items: center;
	list-style-type: none;
	height: 100%;
	padding-left: 0px;
	margin-bottom: 0px;
	/* margin-right: 10px; */
}

.custom-menu-li {
	margin: 0 1vw;
}

.custom-menu-item {
	font-family: RegularFont;
	cursor: pointer;
	&:hover {
		color: @primary-color;
	}
}

/////////////////////footer///////////////////

.footer {
	position: relative;
	bottom: 0;
	background-color: rgba(242, 242, 242, 0.3);
	height: fit-content;
	margin-top: 55px;
	padding-top: 50px;
}

.footer-content {
	padding: 0 6vw;
	display: flex;
	justify-content: space-between;
	height: fit-content;
}

.footer-first-part {
	width: 20vw;
	padding: 20px 0;
}

.footer-second-part {
	display: flex;
	justify-content: space-between;
	width: 24vw;
}

.footer-button {
	margin: 20px 0;
}

.social-icon {
	margin-right: 1vw;
	vertical-align: bottom;
	margin-bottom: 1vw;
}

.footer-title {
	color: @primary-color !important;
	font-size: calc(0.6rem + 0.5vw);
}

.footer-menu {
	list-style-type: none;
	line-height: 3.5rem;
	padding: 0 0;
}

.footer-menu-item {
	font-family: RegularFont;
	color: black !important;
	cursor: pointer;
	padding: 10px 0;
	font-weight: bold;
	font-size: 16px;
}

.footer-menu-item:hover {
	text-decoration: none;
	color: @primary-color;
}

.mobile-footer-part {
	display: none;
}

.social-media-block {
	margin-top: 8px;
}

.footer-text {
	margin: 30px 0;
	line-height: 1.6rem;
	font-weight: bold;
}

.footer-copyrights {
	position: absolute;
	width: 100%;
	height: fit-content;
	background-color: rgba(242, 242, 242, 0.3);
	display: flex;
	justify-content: center;
	padding: 20px 0;
	border-top: 3px solid @normal-color;
	text-align: center;
}

.web-footer-part {
	display: flex;
}

@media (max-width: 87rem) {
	///////footer////////////

	.web-footer-part {
		display: none;
	}
	.footer-content {
		flex-direction: column;
		padding: 50px 4vw;
		align-items: center;
	}

	.footer-first-part {
		width: 80vw;
	}

	.footer-second-part {
		display: flex;
		justify-content: space-between;
		width: 80vw;
	}

	.footer-third-part {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 80vw;
	}

	.mobile-footer-part {
		display: flex;
	}
	.footer-menu-item {
		font-size: 14px;
	}
	.footer-copyrights {
		padding: 30px 0;
	}
	.nav-search-container {
		width: 24vw;
	}
}

@media (max-width: 80rem) {
	.navbar-container {
		display: none;
	}
}

@media (max-width: 30rem) {
	///////footer////////////

	.footer-content {
		padding: 50px 0.5vw;
	}

	.footer-first-part {
		width: 95vw;
	}

	.footer-second-part {
		width: 90vw;
	}

	.footer-third-part {
		width: 95vw;
	}
	.social-icon {
		margin-right: 2vw;
		width: 25px;
	}
	.footer-menu-item {
		font-size: 12px;
	}
}
.disabled-link {
	pointer-events: none;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;