.orange-3d-card {
	background-color: @primary-color;
	width: 100%;
	height: 30vh;
	border-radius: 20px;
	padding: 5%;
	margin-top: 24%;
	display: flex;
	justify-content: space-between;
}

.illustration-image-container-1 {
	width: 25vw;
	margin: -24% 0 0 -10%;
}

.illustration-image-container-2 {
	width: 14vw;
	margin: -24% 0 0 -10%;
}

.orange-card-text {
	color: white;
}

.description-card-become-Creator {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.card-white-button {
	color: @primary-color;
	background-color: white;
	border: none;
	width: 150px;
}

.card-white-button:hover {
	color: @primary-color;
	background-color: white;
}

.illustration-image {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

@media (max-width: 80rem) {
	.orange-3d-card {
		height: 27vh;
	}

	.illustration-image-container-1 {
		width: 30vw;
		margin: -14% 0 0 -10%;
	}

	.illustration-image-container-2 {
		width: 18vw;
		margin: -14% 0 0 -10%;
	}
}

@media (max-width: 60rem) {
	.orange-3d-card {
		height: 27vh;
	}

	.illustration-image-container-1 {
		width: 40vw;
		margin: -14% 0 0 -10%;
	}

	.illustration-image-container-2 {
		width: 28vw;
		margin: -14% 0 0 -10%;
	}
	.react-multi-carousel-item {
		margin-right: 15px;
	}
}

@media (max-width: 30rem) {
	.orange-3d-card {
		height: 23vh;
		margin-right: 10px;
	}

	.illustration-image-container-1 {
		width: 40vw;
		margin: -20% 0 0 -20%;
	}

	.illustration-image-container-2 {
		width: 36vw;
		margin: -34% 0 0 -7%;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;