.item-block-table {
	display: flex;
	align-items: center;
	height: auto;
}
.item-description-table {
	margin-right: -60px;
}

.text-left-item {
	width: 15vw;
}

@media screen and (max-width: 1580px) {
	.item-description-table {
		margin-right: -30px;
	}
	.text-left-item {
		width: 18vw;
	}
}
@media screen and (max-width: 1230px) {
	.item-description-table {
		margin-right: -10px;
	}
	.text-left-item {
		width: 22vw;
	}
}
@media screen and (max-width: 1150px) {
	.item-block-table {
		display: flex;
		align-items: center;
		height: auto;
		padding-top: -2px;
	}
	.text-left-item {
		width: 5vw;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;