.collection-item-existing {
  // width: 470px;
  // display: flex;
  // flex-direction: column;
  // padding: 2.5%;
  // margin-left:30px;
  // border: 2px solid @normal-color;
  // border-radius: 25px;
  // margin-bottom: 32px;
  // padding: 30px;

  .image-collection {
    width: 100%;
    height: 300px;
    border-radius: 25px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }

  .existed-collection-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .collection-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 23px;
    line-height: 0.9rem;

    .other-collection {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: black;
    }
  }
  .ant-avatar{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 50px;
    height: 50px;
    line-height: 37px;
    border-radius: 50%;
  }

  
}



.text-invite-collectionExist{
  font-size: 15px;
    width: 50%;
    position: absolute;
    right: -4px;
    top: -22px;
    color: white;
}
.title-royalatie-items{
  margin-bottom:20px !important;
}

 
  .collections-items{
    min-height: 300px !important;
    display: flex;
    flex-wrap: wrap;
    margin-left: 39px;
 
 }

 @media screen and (max-width: 2600px) {
  .collection-item-existing {
     width: 420px;
  display: flex;
  flex-direction: column;
  padding: 2.5%;
  margin-left: 30px;
  border: 2px solid #E8E8E8;
  border-radius: 25px;
  margin-bottom: 32px;
  padding: 30px
  }
  .collections-items{
     
    display: flex;
    flex-wrap: wrap;
    margin-left: 8px;
 
 }

}

@media screen and (max-width:1920px){

  .collection-item-existing {
    
      width: 385px;
      display: flex;
      flex-direction: column;
      padding: 2.5%;
      margin-left: 27px;
      border: 2px solid #E8E8E8;
      border-radius: 25px;
      margin-bottom: 32px;
      padding: 30px;
     
     }
      .collections-items{
     
            display: flex;
            flex-wrap: wrap;
            margin-left: 20px;
         
         }


}
@media screen and (max-width:1680px){
  .collection-item-existing {
width: 420px;
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    margin-left: 27px;
    border: 2px solid #E8E8E8;
    border-radius: 25px;
    margin-bottom: 32px;
    padding: 30px;
  }
  .collections-items{
     
    display: flex;
    flex-wrap: wrap;
    margin-left: 56px;
 
 }
}
@media screen and (max-width:1440px){
  .collection-item-existing {
    width: 364px;
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    margin-left: 27px;
    border: 2px solid #E8E8E8;
    border-radius: 25px;
    margin-bottom: 32px;
    padding: 30px;

  }
  .collections-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -26px;
}

}
@media screen and (max-width:1290px){
  .collection-item-existing {
    width: 350px;
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    margin-left: 27px;
    border: 2px solid #E8E8E8;
    border-radius: 25px;
    margin-bottom: 32px;
    padding: 30px;

  }
  .collections-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
}

}

@media screen and (max-width:1100px){
  .collection-item-existing {
    width: 350px;
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    margin-left: 70px;
    border: 2px solid #E8E8E8;
    border-radius: 25px;
    margin-bottom: 32px;
    padding: 30px;

  }
  .collections-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
}

}

@media screen and (max-width:900px){
  .collection-item-existing {
    width: 320px;
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    margin-left: 27px;
    border: 2px solid #E8E8E8;
    border-radius: 25px;
    margin-bottom: 32px;
    padding: 30px;

  }
  .collections-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
}

}
@media screen and (max-width:800px){
  .collection-item-existing {
    width: 430px;
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    margin-left: 0px;
    border: 2px solid #E8E8E8;
    border-radius: 25px;
    margin-bottom: 32px;
    padding: 30px;

  }
  .collections-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: 130px;
}

}
@media screen and (max-width:600px){
  .collection-item-existing {
    width: 370px;
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    margin-left: 0 !important;
    border: 2px solid #E8E8E8;
    border-radius: 25px;
    margin-bottom: 32px;
    padding: 30px;

  }
  .collections-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0 !important;
    justify-content: center;
}

}

@media screen and (max-width:500px){
  .collection-item-existing {
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    margin-left: 5px;
    border: 2px solid #E8E8E8;
    border-radius: 25px;
    margin-bottom: 32px;
    padding: 30px;

  }
  .collections-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: 5px;
}

}
@media screen and (max-width:300px){
  .collection-item-existing {
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 2.5%;
    margin-left: 27px;
    border: 2px solid #E8E8E8;
    border-radius: 25px;
    margin-bottom: 32px;
    padding: 30px;

  }
  .collections-items {
    display: flex;
    flex-wrap: wrap;
    //margin-left: 5px;
}

}


@media screen and (max-width:260px){
.collection-existing-scroll{
  overflow: auto !important;
  width:500px !important
}
  .collection-item-existing {
   
    display: flex;
    flex-direction: column;
    border: 2px solid #E8E8E8;
    border-radius: 25px;
    margin-bottom: 32px;
    
   

  }
  .collections-items {
    display: flex;
    flex-wrap: wrap;
  
}

}
@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;