.mobile-navbar-container {
  display: none;
}

.mobile-logo {
  width: 7vw;
  min-width: 100px;
}

.mobile-menu-icon {
  width: 17px;
}

.mobile-menu-button {
  width: fit-content;
  padding: 5px 10px;
  margin-left: 10px;
  background-color: @primary-color;
  border-radius: 10px;
  cursor: pointer;
}

.mobile-menu-drawer {
  margin-top: 50px;
  margin-left: -30px;
  list-style-type: none;
  line-height: 2.5rem;
}

.mobile-menu-item {
  cursor: pointer;
  padding: 10px 0;
}

.mobile-auth-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.mobile-search {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobilefloatingNav {
  border-radius: 2px;
  box-shadow: 0px 1px 10px rgb(201, 201, 201);
}
@media (max-width: 80rem) {
  .mobile-navbar-container {
    height: 70px;
    padding: 0 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: white;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;