.profile-button-container {
  border: 2px solid @primary-color;
  border-radius: 15px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  height: 50px;
}

.profile-button-text-container {
  margin: 0 10px;

  h6 {
    margin: 0;
  }
}

.navbar-avatar-image-container {
  width: 35px;
  height: 35px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.navbar-avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-dropdown-item {
  display: flex;
  padding: 8px 7px;
}

.profile-dropdown-item-icon {
  width: 15px;
  margin: 0 10px;
}

.profile-dropdown-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  min-width: 250px;
}

.tiny-font {
  font-size: calc(0.9rem);
  display: flex;
  align-items: center;
}

.profile-dropdown-menu-item{
  border-radius: 15px;
}

.egld-icon {
  width: 15px;
  margin: 0 0 0 3px;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;