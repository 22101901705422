.auction-card {
	width: 100%;
	border: 3px solid @normal-color;
	border-radius: 25px;
	padding: 4%;
	padding-bottom: 1vw;
}

.auction-card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
}

.header-title {
	margin: 0 0 10px 0;
}

.action-element-container {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-top: 3px solid @normal-color;
}

.price-part {
	display: flex;
	align-items: center;
	padding: 0;
}

.price-logo {
	margin: 0 10px;
	width: 20px;
}

.auction-link-button {
	height: fit-content;
}

.thumbnail-container {
	margin: 0 20px;
	width: 60px;
	height: 60px;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
}

.thumbnail-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.auction-element-first-part {
	display: flex;
	align-items: center;
	margin: 10px 0;
}

.auction-element-second-part {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.h5-auction-text {
	line-height: 1.2rem;
}

@media (max-width: 65rem) {
	.auction-card {
		width: 80%;
		border: 3px solid @normal-color;
		border-radius: 25px;
		padding: 4%;
		padding-bottom: 1vw;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;