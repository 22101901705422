.activity-main {
	width: 100%;
	font-size: 12px;
	z-index: 10;
	top: 95px;
	position: fixed;
	background: white;
}
.filterMessage {
	margin: 0 5px;
}
.optionSelect {
	margin-left: 20px;
}
.optionSelect > .activity-select {
	font-size: 16px;
}
.table-activity .ant-spin-nested-loading .ant-spin-container {
	top: 135px;
}
.activity-filter {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	max-width: 83%;
	margin: auto;
	z-index: 10;
}
@media screen and (max-width: 1300px) {
	.activity-main {
		top: 67px;
	}
	.table-activity .ant-spin-nested-loading .ant-spin-container {
		top: 103px;
	}
}
@media screen and (max-width: 1000px) {
	.table-activity .ant-spin-nested-loading .ant-spin-container {
		top: 200px !important;
	}
}

@media screen and (max-width: 950px) {
	.table-activity .ant-spin-nested-loading .ant-spin-container {
		top: 195px !important;
	}
}

@media screen and (max-width: 765px) {
	.table-activity .ant-spin-nested-loading .ant-spin-container {
		top: 285px !important;
	}
	.ant-table.ant-table-small {
		border: 2px solid #efeeee !important;
		margin-bottom: 90px;
		max-width: 93%;
		margin: auto;
	}
}

@media screen and (max-width: 500px) {
	.table-activity .ant-spin-nested-loading .ant-spin-container {
		top: 310px !important;
	}
}
.selectors-select {
	margin-top: 30px;
}
.current {
	margin-top: 71px;
}
.table-activity .table-responsive {
	margin-top: 20px;
}

.table-responsive {
	overflow-x: clip !important;
}
.pagination-activity {
	max-width: 83%;
	margin: auto;
	margin-bottom: -49px;
}
.link-home-activity {
	font-size: 16px !important;
}

.home-link-activity {
	color: black !important;
	font-size: 16px;
}
.button-seeMore {
	display: flex;
	margin: auto;
}

@media screen and (max-width: 475px) {
	#root > div > div.main-body > div > div.activity-main > div > div,
	h4 {
		margin: auto !important;
	}
}

@media screen and (min-width: 476px) {
	#root > div > div.main-body > div > div.activity-main > div > div {
		display: flex;
		flex-wrap: wrap;
	}
}

.text-from-table {
	padding-left: 10px;
	color: @primary-color;
}
.text-from {
	color: @primary-color;
}
.ant-table.ant-table-small {
	margin: auto 20px;
}
.ant-select-selection-item-remove {
	display: none !important;
}

.search-input {
	padding: 10px 14px;
}

.activity-option {
	align-self: flex-end;
	display: flex;
	flex-direction: column;
	width: 170px;
}
.img-item {
	height: 50px;
	width: 50px !important;
	margin-top: 10px !important;
	border-radius: 10px;
}
.collection-text {
	margin-left: 8px;
}

.img-fromto {
	border-radius: 50px;
	width: 45px;
	height: 45px;
}
.ant-select-item {
	padding: 0 !important;
}
.ant-select-item-option-active:hover,
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: transparent !important;
	padding: 0 !important;
}
.ant-checkbox-wrapper:hover,
.ant-radio-wrapper:hover {
	background-color: @info-color !important;
}
.ant-checkbox-group-item {
	margin: 0 !important;
}
.ant-checkbox-group,
.ant-radio-group,
.ant-space {
	width: 100%;
}
.ant-checkbox-wrapper,
.ant-radio-wrapper {
	padding: 10px;
	border-bottom: 1px solid #eae9e8;
	width: 100%;
	font-size: 13px;
}
.ant-select-dropdown {
	padding: 0 !important;
	position: fixed;
}

.ant-table-thead
	> tr
	> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}
.activity-select {
	display: flex;
	flex-direction: column;
}

.activity-main .ant-space-vertical {
	flex-direction: column;
	// margin-left: 20px;
}

.activity-select {
	color: @primary-color;
	margin-left: 26px;
}

.ant-table.ant-table-small {
	border: 2px solid #efeeee;
	margin-bottom: 160px;
}

@media screen and (max-width: 500px) {
	.ant-table.ant-table-small {
		border: none;
	}
	.font-trading-history-header {
		font-size: 11px;
	}
}

.ant-table-container table > thead > tr:first-child th:first-child {
	padding: 25px;
}
table > thead .event-table-header {
	padding: 10px !important;
}

.checkbox-activity {
	font-weight: bold;
	font-size: 12px;
	width: 180px;
	overflow-x: hidden !important;
}
.checkbox-collection {
	font-weight: bold;
	font-size: 12px;
	width: 221px !important;
	overflow-x: hidden !important;
}

.item-text {
	padding-left: 22px;
	padding-top: 13px;
}
.img-collection {
	width: 25px;
	height: 25px;
	border-radius: 5px;
}
.img-collection ::before {
	display: none;
}
.text-collection {
	margin-left: 10px;
}

.ant-checkbox-group-item {
	display: flex !important;
	line-height: 1.5715 !important;
}

.ant-checkbox:nth-child(1) {
	top: 4px;
}
.ant-checkbox + span {
	font-size: small;
}

.ant-pagination.mini.ant-table-pagination.ant-table-pagination-right {
	opacity: 0 !important;
}
.ant-table-small .ant-table-thead > tr > th {
	background-color: transparent;
}
.site-page-header-ghost-wrapper {
	padding: 24px;
	background-color: #f5f5f5;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;