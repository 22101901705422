.success-modal-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upload-banner-button .ant-upload{
  flex-basis: 100%;
  text-align: center;
}
.uploadBanner{
  padding: 13px;
}
.success-modal-button {
  margin: 10px 0;
}

.item-success-title {
  text-align: center;
  margin: 20px 0;
}

.item-image-preview-container {
  // width: 32vw;
  height: 300px;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
}

.item-image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-image-button-container {
  position: absolute;
  top: 11%;
  right: 11%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  // background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-item-collection-name {
  color: @primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.create-item-container {
  margin: 5% 0;
  display: flex;
  justify-content: space-between;
}
.custom-item-btn{
  border: 0;
  border-radius: 15px;
  padding: 10px;
}
@media (max-width: 50rem) {
  .create-item-container {
    flex-direction: column;
  }
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;