.thumbnail-image-propositions-container {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
}

.thumnail-images {
	display: flex;
	justify-content: center;
}
.thumbnail-image-proposition {
	width: 100%;
	height: calc(150px);
	border-radius: 15px;
	position: relative;
	overflow: hidden;
	border: 3px solid transparent;
	cursor: pointer;
	margin: 0 5px;
}

.thumbnail-image-highlighted-proposition {
	border: 3px solid @primary-color;
}

.thumbnail-image-checked-button-container {
	position: absolute;
	top: 8%;
	right: 5%;
	color: white;
}

.thumbnail-image-show-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;