.extra-file-container-bloc {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	.play {
		margin-left: 0px;
	}
	button {
		margin: 3px;
		padding: 6px 12px;
		border: 0;
		border-radius: 5px;
		border: 1px solid rgb(160, 160, 160);
		outline: 0;
		background-color: #eee;
		height: 38px;
	}
	video {
		border-radius: 8px;
	}
}
.react-player__preview {
	border-radius: 8px;
}
.extra-file-dragger-bloc {
	width: 27vw;
	height: 27vw;
}
.modal-preview {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.thumbnail-image-block {
	display: flex;
	width: inherit;
	justify-content: center;
	align-items: center;
	margin-top: 20;
}
.audio-block {
	width: 420px;
}
.icon-play-video {
	color: #ec6321;
	height: 20px;
}

.audio-block-preview {
	width: 100%;
	margin-top: 70px;
}
.audio-container-modal {
	width: 100%;
}
.block-video-play {
	width: 27vw !important;
	height: auto !important;
}

.delete-container {
	background: #d9d9d9 0% 0% no-repeat padding-box;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	width: 2vw;
	height: 2vw;
	border-radius: 2.5vw;
	color: #ec6321;
	align-self: flex-end;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-bottom: 10px;
	position: absolute;
	top: 5%;
	right: 5%;
	z-index: 2;
}

.canvas-3d {
	height: 350px !important;
	border-radius: 8px;
}
.canvas-3d-without-radius {
	height: 350px !important;
}

.item-details-canvas-3d {
	height: 450px !important;
	border-radius: 8px;
}
.item-details-left-item {
	height: 140px !important;
	border-radius: 8px;
}

.spin-3d {
	display: flex;
	justify-content: center;
}
@media (max-width: 80rem) {
	.extra-file-dragger-bloc {
		width: 28vw;
		height: 28vw;
		.icon-media-upload {
			width: 7vw;
			height: 7vw;
		}
		.ant-upload.ant-upload-drag p.ant-upload-text {
			font-size: 17px;
		}
		.ant-upload.ant-upload-drag p.ant-upload-hint {
			font-size: 14px;
		}
	}
	.button-video {
		font-size: 12px;
	}
	.delete-container {
		width: 2.5vw;
		height: 2.5vw;
		svg {
			height: 12px;
		}
	}
	.canvas-3d {
		height: 230px !important;
		border-radius: 8px;
	}
}
@media (max-width: 50rem) {
	.extra-file-dragger-bloc {
		width: 50vw;
		height: 50vw;
		.icon-media-upload {
			width: 10vw;
			height: 10vw;
		}
		.ant-upload.ant-upload-drag p.ant-upload-text {
			font-size: 16px;
		}
		.ant-upload.ant-upload-drag p.ant-upload-hint {
			font-size: 14px;
		}
	}
	.block-video-play {
		width: 50vw !important;
		height: auto !important;
	}
	.canvas-3d {
		height: 300px !important;
		border-radius: 8px;
	}
}
@media (max-width: 35rem) {
	.extra-file-dragger-bloc {
		width: 70vw;
		height: 70vw;
		.icon-media-upload {
			width: 13vw;
			height: 13vw;
		}
		.ant-upload.ant-upload-drag p.ant-upload-text {
			font-size: 14px;
		}
		.ant-upload.ant-upload-drag p.ant-upload-hint {
			font-size: 12px;
		}
	}
	.block-video-play {
		width: 70vw !important;
		height: auto !important;
	}
	.canvas-3d {
		height: 230px !important;
		border-radius: 8px;
	}
}

@media (max-width: 30rem) {
	.audio-block {
		width: 320px;
	}
}
@media (max-width: 25rem) {
	.audio-block {
		width: 250px;
	}
}
@media (max-width: 20rem) {
	.audio-block {
		width: 200px;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;