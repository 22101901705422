.title-2 {
	margin-top: -2vh;
	line-height: 2.3em;
}

.auth-page-container {
	height: 100vh;
}

.back-icon {
	margin: 0 10px;
	width: 2.3vw;
}

.back-button {
	width: fit-content;
	color: black !important;
	margin-top: 30px;
	display: flex;
	align-items: center;

	h4 {
		margin: 0;
	}
}

.back-button:hover {
	color: black;
	cursor: pointer;
}

.buttons-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;
	width: 40vw;
}

.login-part {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.login-container {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.other-option-button {
	cursor: pointer;
	padding: 0 20px;
	width: fit-content;
	margin-bottom: 30px;
}

.other-option-link {
	color: @primary-color;
}

.xportal-description {
	line-height: 1.2rem;
}

.ledger-logo {
	width: 200px;
	margin: 40px;
}

.reverse-arrow {
	transform: rotate(180deg); /* Equal to rotateZ(45deg) */
}

.login-loading-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-loading-container div {
	text-align: center;
}

.login-loading-container h3 {
	padding-top: 20px;
}

.auth-buttons-container {
	margin: 20px 0;
	display: flex;
	padding: 20px 10px;
	justify-content: space-between;
	border: 2px solid #e8e8e8;
	border-radius: 10px;
}

@media (max-width: 60rem) {
	.login-part {
		margin-top: 10vh;
	}
	.buttons-container {
		width: 90vw;
	}
	.back-icon {
		margin: 0 10px;
		width: 4vw;
		min-width: 20px;
	}
	.back-text {
		margin: 0 !important;
	}
}

@media (max-width: 40rem) {
	.auth-buttons-container {
		flex-direction: column;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;