.item-container {
	margin-top: 20px;
	margin-bottom: 35px;
}
.item-image-container {
	width: 100%;
	height: calc(200px + 20vh);
	border-radius: 25px;
	position: relative;
	overflow: hidden;
}

.item-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
}

.item-description {
	display: flex;
	justify-content: space-between;
	align-items: center;
	line-height: 1.5rem;
	text-align: left;
}
.item-avatar-block {
	display: flex;
	justify-content: center;
	align-items: center;
}

.price-holder {
	border: 2px solid @primary-color;
	height: fit-content;
	border-radius: 10px;
	padding: 2px 4px;
}

.price-tag {
	color: @primary-color;
	margin: 3px 7px;
	padding: 0;
}

.item-text-container {
	line-height: 1.5rem;
	margin: 20px 0;
	width: 9vw;
}
.nameOwner {
	color: black;
	margin-left: 13px;
}
.like-button-container {
	position: absolute;
	top: 5%;
	right: 5%;
	cursor: pointer;
}
.like-button-container-collection-item {
	position: absolute;
	top: 11%;
	right: 11%;
	cursor: pointer;
}

.empty-container {
	background: #ffffff 0% 0% no-repeat padding-box;
	opacity: 0.6;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	position: relative;
	width: 3.5vw;
	height: 3.5vw;
	border-radius: 3.5vw;
}

.filled-heart {
	width: 3.5vw;
}

.empty-heart {
	position: absolute;
	top: 25%;
	right: 24%;
	width: 1.8vw;
}

.loading-item-image-container {
	width: 250px;
	height: calc(200px + 15vh);
	border-radius: 25px;
	position: relative;
	overflow: hidden;
}

.loading-item-description {
	margin: 20px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mobile-recent-items-spin {
	width: 100%;
	height: calc(200px + 20vh);
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 1280px) {
	.item-container {
		padding: 16px !important;
	}
}
@media (max-width: 60rem) {
	.empty-container {
		width: 5.5vw;
		height: 5.5vw;
		border-radius: 5.5vw;
	}
	.item-container {
		padding: 25px !important;
	}

	.filled-heart {
		width: 5.5vw;
	}

	.empty-heart {
		width: 3vw;
	}
	.item-text-container {
		line-height: 1.5rem;
		margin: 20px 0;
		width: 7.5vw;
	}
}
@media (max-width: 54rem) {
	.empty-container {
		width: 5.5vw;
		height: 5.5vw;
		border-radius: 5.5vw;
	}
	.item-container {
		padding: 25px !important;
	}

	.filled-heart {
		width: 5.5vw;
	}

	.empty-heart {
		width: 3vw;
	}
	.item-text-container {
		line-height: 1.5rem;
		margin: 20px 0;
		width: auto;
	}
}
@media (max-width: 50rem) {
	.empty-container {
		width: 5.5vw;
		height: 5.5vw;
		border-radius: 5.5vw;
	}
	.item-container {
		padding: 25px !important;
	}

	.filled-heart {
		width: 5.5vw;
	}

	.empty-heart {
		width: 3vw;
	}
	.item-text-container {
		line-height: 1.5rem;
		margin: 20px 0;
		width: 14vw;
	}
}
@media (max-width: 48rem) {
	.empty-container {
		width: 5.5vw;
		height: 5.5vw;
		border-radius: 5.5vw;
	}
	.item-container {
		padding: 25px !important;
	}

	.filled-heart {
		width: 5.5vw;
	}

	.empty-heart {
		width: 3vw;
	}
	.item-text-container {
		line-height: 1.5rem;
		margin: 20px 0;
		width: auto;
	}
}

@media (max-width: 40rem) {
	.empty-container {
		width: 9vw;
		height: 9vw;
		border-radius: 9vw;
	}

	.filled-heart {
		width: 9vw;
	}

	.empty-heart {
		width: 5vw;
	}
	.item-text-container {
		line-height: 1.5rem;
		margin: 20px 0;
		width: auto;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;