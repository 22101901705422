.action-description-text {
	padding: 20px 70px;
}

.item-details-auction-box {
	width: 100%;
	border: 1px solid @normal-color;
	border-radius: 10px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.auction-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.title-auction {
	color: @primary-color;
}

.details-auction {
	margin: 20px;
}

.place-bid-buttons-holder {
	width: 100%;
	display: grid;
	grid-template-columns: 47% 47%;
	grid-gap: 6%;
}

.place-bid-price-container {
	h4 {
		margin: 0;
	}
}

.approximative-price {
	color: @dark-gray-color;
}

.place-bid-content {
	display: flex;
	justify-content: space-between;
}

.date-number-part {
	margin: 0px 5px 5px 5px;
}

.date-number-container {
	background-color: @dark-gray-color;
	padding: 5px;
	border-radius: 3px;
	margin-bottom: 10px;
}

.end-date-container {
	display: flex;
	margin-bottom: 20px;
	justify-content: center;
}

.desc-auction {
	padding-top: 10px;
}

.details-auction {
	padding-bottom: 20px;
}

.egld-icon {
	width: 18px;
	margin: 0 5px;
}

.approve-item-buttons-holder {
	display: flex;
	justify-content: space-between;
	flex-wrap: auto;
	margin: 20px 0;
}

.admin-buttons {
	color: white;
	width: 100%;
	height: 50px;
	border-radius: @border-radius-base;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin: 0 5px;
}

.admin-decline-button {
	background-color: @red-color;
}

.admin-approve-button {
	background-color: @green-color;
}

.button-auction {
	margin-top: 0px;
}
.points-timer {
	margin-top: 0.3vw;
}
@media (max-width: 1580px) {
	.points-timer {
		margin-top: 4px;
	}
}
@media (max-width: 1200px) {
	.points-timer {
		margin-top: 3px;
	}
}
@media (max-width: 850px) {
	.points-timer {
		margin-top: 2px;
	}
}

@media (max-width: 35rem) {
	.place-bid-content {
		flex-direction: column;
	}

	.place-bid-buttons-holder {
		display: flex;
		flex-direction: column;
	}
	.place-bid-button-holder {
		margin-bottom: 5px;
	}
	.approve-item-buttons-holder {
		display: flex;
		flex-direction: column;
		margin: 20px 0;
	}

	.admin-buttons {
		margin: 5px 0px;
	}
	.purchase-now-block-btn {
		margin-top: 5px;
	}
	.points-timer {
		margin-top: 1px;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;