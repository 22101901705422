.collection-show-page {
  margin: 15vh 0;
}

.collection-tab-loading-content {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collection-profile-noData-holder {
  width: 100%;
}

.ant-select-selector .ant-select-selection-item {
  font-size: 13px;
}
.tabBtnExitingCol {
  float: right;
}

.nodataFound {
  width: 50%;
  margin: 20px auto;
}

.profileDetails_tab .collection {
  // grid-template-columns: 25% 25% 25% 25%!important;
  margin: auto;
}

.profile-sider-container-collection {
  width: 19%;
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  height: -moz-fit-content;
  height: fit-content;
}

.sortby_option .ant-select-item-option-content {
  padding: 10px;
}

.sortby_option .ant-select-item-option-content:hover {
  background-color: @info-color;
}

.collection-profile .button-collection-tab {
  width: 193px !important;
}

.collection-profile-contenu {
  width: 59% !important;
  margin-left: -286px;
}

.collection-profile {
  width: 84%;
  display: grid;
  grid-template-columns: 22% 22% 22% 22% auto;
  grid-gap: 2%;
  margin-left: 16px;
}

.sort-by-collection {
  display: flex;
  justify-content: end;
  margin-right: 5px;
}

.select-option {
  width: 195px !important;
}

.collection-show-header {
  margin: 0 4vw;
}

.collection-show-content {
  //margin: 0 7vw;
  display: grid;
  //grid-template-columns: 350px auto;
  grid-gap: 0%;
}

.optionCol .ant-select-item-option-content {
  padding: 10px;
}

.optionCol .ant-select-item-option-content:hover {
  background-color: @info-color;
}

.collection-profile-mobile-tab {
  display: none;
}

.desktop-tab-content-items-container-collection-tab .items-collection-tab-page {
  margin-top: 10px;
}

.collection-profile-add-item {
  width: 316px !important;
  height: 367px !important;
  background-color: @info-color;
  border-radius: 20px;
  border: 3px dashed @primary-color;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-container-collection
  .colled-loder
  .item-container
  .loading-item-image-container {
  width: 430px !important;
}
.img-container-collection
  .colled-loder
  .item-container
  .loading-item-image-container
  .ant-skeleton.ant-skeleton-element
  .ant-skeleton-image {
  width: 440px !important;
}
@media screen and (max-width: 2400px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 410px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 420px !important;
  }
}
@media screen and (max-width: 2300px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 380px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 380px !important;
  }
}
@media screen and (max-width: 2100px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 340px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 340px !important;
  }
}

@media screen and (max-width: 1900px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 330px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 330px !important;
  }
}

@media screen and (max-width: 1800px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 320px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 320px !important;
  }
}

@media screen and (max-width: 1700px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 300px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 309px !important;
  }
}
@media screen and (max-width: 1600px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 280px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 290px !important;
  }
}

@media screen and (max-width: 1600px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 270px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 280px !important;
  }
}
@media screen and (max-width: 1400px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 260px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 270px !important;
  }
}

@media screen and (max-width: 1100px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 280px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 290px !important;
  }

  .loading-mobile-size {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 260px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 280px !important;
  }
  .contentCollection {
    text-align: -webkit-center;
  }
}
@media screen and (max-width: 900px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 350px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 360px !important;
  }
}
@media screen and (max-width: 800px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 300px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 310px !important;
  }
}
@media screen and (max-width: 700px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 270px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 280px !important;
  }
}
@media screen and (max-width: 600px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 250px !important;
  }
  .contentCollection {
    flex-flow: column !important;
  }

  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 260px !important;
  }
}

@media screen and (max-width: 500px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 310px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 320px !important;
  }
}

@media screen and (max-width: 360px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 280px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 290px !important;
  }
}
@media screen and (max-width: 330px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 250px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 260px !important;
  }
}
@media screen and (max-width: 250px) {
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container {
    width: 160px !important;
  }
  .img-container-collection
    .colled-loder
    .item-container
    .loading-item-image-container
    .ant-skeleton.ant-skeleton-element
    .ant-skeleton-image {
    width: 170px !important;
  }
  .img-container-collection {
    margin-left: -2px;
  }
  .profile-sider-row .ant-avatar-group {
    flex-wrap: wrap;
  }
}
.collection-profile-add-item-new-collection {
  width: 100%;
  margin-top: 20px;
  background-color: #ffdfd1;
  border-radius: 20px;
  border: 3px dashed #ec6321;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 370px;
  //margin-bottom: 180px;
}
.collection-profile .item-image {
  width: 300px;
  height: 337px;
}

@media (max-width: 1920px) {
  .collection-profile-add-item-new {
    margin-bottom: 135px;
    width: 348px;
    background-color: #ffdfd1;
    border-radius: 20px;
    border: 3px dashed #ec6321;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 395px;
  }
}

@media (max-width: 1680px) {
  .collection-profile-add-item-new {
    margin-bottom: 118px;
    width: 327px;
    background-color: #ffdfd1;
    border-radius: 20px;
    border: 3px dashed #ec6321;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 395px;
  }
  .img-container-items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
}

.collection-profile-add-item-holder {
  text-align: center;
}

.mobile-tab-content-items-container {
  display: none;
}

.collection-profile-items-carousel {
  margin: 30px 0;
}

@media (max-width: 85rem) {
  .collection-profile-mobile-tab {
    display: flex;
    margin-top: 32px;
    margin-left: 23px;
    width: 100%;
  }

  .desktop-tab-content-items-container {
    //display: none;
  }

  .profile-tab-part {
    width: 60vw;
  }

  .mobile-tab-content-items-container {
    display: block;
  }
}

@media (max-width: 75rem) {
  .profile-tab-part {
    width: 55vw;
  }
}

@media (max-width: 70rem) {
  .profile-tab-part {
    width: 50vw;
  }
}

@media (max-width: 65rem) {
  .profile-tab-part {
    width: 45vw;
  }
}

@media (max-width: 50rem) {
  .collection-show-content {
    display: flex;
    flex-direction: column;
  }
  .profile-tab-part {
    margin: 30px 0;
  }
  .profile-tab-part {
    width: 85vw;
  }
}
.collection-show-c {
  display: flex;
  justify-content: space-around;
}

.collection-profile-not-connect {
  width: 110%;
  display: grid;
  grid-template-columns: 19% 19% 19% 19% auto;
  grid-gap: 5%;
  margin-left: 11px;
}
.collection-profile .ant-btn {
  padding: 0px !important;
}

.img-container-collection {
  margin-top: 50px;
}

@media screen and (max-width: 1500px) {
  // .profile-tab-responsive {
  //   display: none;
  // }
  .img-container-collection {
    margin-left: 50px;
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 1900px) {
  .img-container-collection {
    width: 100%;
  }
}

@media screen and (max-width: 1700px) {
  .collection-profile {
    width: 91%;
    display: grid;
    grid-template-columns: 21% 21% 21% 21% auto;
    grid-gap: 1%;
    margin-left: 17px;
  }
  .collection-profile .button-collection-tab {
    width: 172px !important;
    font-size: 15px;
  }
  .select-option {
    width: 176px !important;
  }
}
@media screen and (max-width: 1500px) {
  .collection-profile-contenu {
    width: 61% !important;
  }
  .collection-profile {
    width: 120%;
    display: grid;
    grid-template-columns: 13% 13% 13% 13% auto;
    grid-gap: 1%;
    margin-left: 1px;
  }
  .collection-profile .button-collection-tab {
    width: 150px !important;
    font-size: 15px;
  }
  .collection-profile-contenu {
    width: 60% !important;
    margin-left: -196px;
  }
  .profile-sider-container-collection {
    width: 21%;
    margin-top: 30px;
  }
  .collection-show-c {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .sort-by-collection {
    margin-right: -2px;
  }
  .collection-profile {
    margin-top: 50px;
    margin-left: 55px;
  }
  .img-container-collection {
    margin-left: 4px;
    margin-top: 0px !important;
  }
  .tab-header-mobile .collection-profile-mobile-tab {
    margin-top: 50px;
  }
  .collection-profile-mobile-tab {
    // display: flex;
    margin-top: 32px;
    margin-left: 13px;
    width: 99%;
  }
  .select-option {
    width: 254px !important;
  }
}

@media screen and (max-width: 1300px) {
  .sort-by-collection {
    margin-right: 0px;
  }
  .item-new-collection {
    width: 241px;
    height: 294px;
  }
  .hide-collection-add-item {
    padding-right: 24px;
    margin-left: 2px;
  }
  .select-option {
    width: 244px !important;
  }
  .collection-profile-contenu {
    width: 65% !important;
    margin-left: -69px;
  }
  .img-container-collection-not-connect {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    /* width: 50%; */
    width: 190%;
  }
  .profile-sider-container-collection {
    width: 24%;
    margin-top: 30px;
  }
}
.container-self-one-item {
  margin-top: 70px;
}
@media screen and (max-width: 1200px) {
  .collection-profile-contenu {
    width: 61% !important;
    margin-left: -48px;
  }
  .profile-sider-container-collection {
    width: 28%;
  }
  .select-option {
    width: 309px !important;
  }

  .desktop-tab-content-items-container-collection-tab .ant-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .sort-by-collection {
    margin-right: 5px;
  }
  .collection-profile-mobile-tab {
    width: 97%;
  }
}
@media screen and (max-width: 1000px) {
  .hide-collection-add-item {
    padding-right: 14px;
    margin-left: 4px;
  }
  .img-container-collection {
    margin-left: 15px;
    margin-top: 0px !important;
  }
  .collection-profile-add-item-new-collection {
    width: 246px;

    height: 300px !important;
  }

  .img-container-collection {
    margin-left: -4px;
    margin-top: 0px !important;
  }
  .profile-sider-container-collection {
    width: 29%;
  }
}

@media screen and (max-width: 900px) {
  .collection-profile-contenu {
    margin-left: -6px;
    width: 85% !important;
  }
  .select-option {
    width: 328px !important;
  }
  .collection-profile-mobile-tab {
    margin-left: 25px;
    width: 93%;
  }
  .sort-by-collection {
    margin-right: 28px;
  }
  .collection-profile-mobile-tab {
    margin-left: 31px;
  }
  .img-container-collection {
    margin-left: -1px;
  }

  .collection-profile-add-item-new-collection {
    width: 204px;
    height: 330px;
  }

  .img-container-collection .hide-collection-add-item {
    margin-left: 15px;
  }
  .collection-profile-add-item-new-collection {
    width: 227px;
    height: 330px;
  }

  .profile-sider-container-collection {
    width: 38%;
  }
  .collection-profile-contenu {
    width: 81% !important;
  }
}

@media screen and (max-width: 870px) {
  .hide-collection-add-item {
    padding-right: 24px;
    margin-left: 36px;
  }
  .img-container-collection {
    margin-left: -3px;
  }
  .profile-sider-container-collection {
    width: 41%;
  }
  .collection-profile-contenu {
    width: 90% !important;
  }
  .profile-sider-container-collection {
    width: 56%;
  }
}
@media screen and (max-width: 700px) {
  .collection-profile-add-item-new-collection {
    width: 188px;
    height: 330px;
  }
}

@media screen and (max-width: 750px) {
  .collection-profile-add-item-new-collection {
    width: 227px;
    height: 330px;
  }
  .img-container-collection {
    margin-left: 0px;
  }
}

@media screen and (max-width: 600px) {
  .collection-profile-add-item-new-profile-page {
    width: 291px;
    background-color: #ffdfd1;
    border-radius: 20px;
    border: 3px dashed #ec6321;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 395px;
  }
}
@media screen and (max-width: 500px) {
  .collection-profile-contenu {
    width: 82% !important;
    margin-left: 11px;
  }
  .profile-sider-container-collection {
    width: 80% !important;
  }
  .sort-by-collection {
    margin-right: 27px;
  }
  .select-option {
    width: 291px !important;
  }

  .collection-profile-mobile-tab {
    width: 91%;
    margin-left: 8px;
  }
}
@media screen and (max-width: 400px) {
  .profile-sider-container-collection {
    width: 84% !important;
  }
  .collection-profile-mobile-tab {
    width: 86%;
    margin-left: 9px;
  }
  .sort-by-collection {
    margin-right: 33px;
  }
  .select-option {
    width: 247px !important;
  }
  .collection-profile-contenu {
    width: 83% !important;
    margin-left: 15px;
  }

  .img-container-collection {
    margin-left: 2px;
  }
}

@media screen and (max-width: 300px) {
  .img-container-collection {
    margin-left: 13px;
  }
  .img-container-collection {
    width: 100%;
  }
  .img-container-collection .ant-col.ant-col-8 {
    max-width: 92% !important;
    padding-left: 8px !important;
    padding-right: 7px !important;
  }
}

@media screen and (max-width: 230px) {
  .profile-sider-container-collection {
    width: 98%;
    margin-left: 22px;
  }
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;