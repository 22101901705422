@import "~antd/dist/antd.less";

* {
    font-family: @font-family;
}
.ant-tooltip {
    font-size: 14px !important;
}

// LIGHT MODE

body.light,
body.light .navbar-container,
body.light .mobile-navbar-container {
    background-color: white;
}
body.light h1,
body.light h2,
body.light h4,
body.light h5,
body.dark .ql-picker-label,
body.dark .ql-editor * {
    color: #000000;
}

// DARK MODE

body.dark,
body.dark .ant-table-small .ant-table-thead > tr > th,
body.dark .activity-main,
body.dark .ant-table,
body.dark .ant-dropdown-menu,
body.dark .ant-table-footer,
body.dark .navbar-container,
body.dark .mobile-navbar-container,
body.dark .ant-drawer-content,
body.dark .ant-select-dropdown,
body.dark .progress-bar,
body.dark .ant-collapse-content,
body.dark .custom-options.custom-select-open,
body.dark .dapp-core-component__styles__bar,
body.dark .footer,
body.dark .footer-copyrights,
body.dark .ant-card-bordered {
    background-color: #111111 !important;
}
body.dark .ant-dropdown-menu,
body.dark .custom-select-open,
body.dark .ant-select-dropdown {
    background-color: #111111 !important;
    border: 1px solid #e8e8e8;
}
body.dark .back-icon-genesis {
    filter: none;
}
body.dark .back-text-genesis {
    color: #000000 !important;
}

body.dark .detail-container,
body.dark .secondary-button,
body.dark .button-seeMore,
body.dark .item-tab:hover,
body.dark .cancelBanner,
body.dark .collections-button,
body.dark .ant-input-group-addon,
body.dark .ant-modal-content,
body.dark .ant-modal-header,
body.dark .ant-modal-footer button:first-child,
body.dark .ant-btn.ant-btn-default,
body.dark .light-button,
body.dark .toast,
body.dark .auction-infos-box,
body.dark .ant-select-multiple .ant-select-selection-item,
body.dark .ant-radio-wrapper:hover,
body.dark .ant-checkbox-wrapper:hover,
body.dark .option-explore .ant-select-item-option-content:hover,
body.dark .itemNotif:hover,
body.dark .sortby_option .ant-select-item-option-content:hover,
body.dark .active-tab,
body.dark .ant-message-notice-content,
body.dark .ql-picker-options,
body.dark .ql-picker-options:hover,
body.dark .profile-tab:hover,
body.dark option,
body.dark .ql-tooltip,
body.dark .not-intereseted-button,
body.dark
    .dapp-core-component__transactionsToastList-styles__toasts.dapp-core-component__transactionsToastList-styles__toastWrapper {
    background-color: #686363 !important;
    color: #ffffff;
}
body.dark .dapp-core-component__transactionDetails-styles__status {
    color: #ffffff;
}
body.dark
    .dapp-core-component__transactionToast-styles__content
    .dapp-core-component__transactionToast-styles__right
    .dapp-core-component__transactionToast-styles__heading
    .dapp-core-component__transactionToast-styles__close {
    color: #ffffff;
}
body.dark
    .dapp-core-component__explorerLinkStyles__link
    .dapp-core-component__explorerLinkStyles__search {
    color: #ffffff;
}
body.dark
    .dapp-core-component__transactionDetails-styles__container
    .dapp-core-component__transactionDetails-styles__icon {
    color: #ffffff;
}

body.dark .checked-checkbox-container {
    background-color: #d79f86;
}

body.dark .css-upwegn-TopBar {
    background-color: #686363 !important;
    color: #ffffff;
}

body.dark .custom-option:hover {
    background-color: #686363 !important;
    color: #ec6321;
}

// dark mode button close react-timepicker
body.dark .dark-button-picker {
    background: #686363;
    color: #ffffff;
}
// dark mode react-timepicker

body.dark .item-wrapper .react-timekeeper__clock-wrapper {
    background: #686363;
    --meridiem-bg-color: #ffdfd1;
    --meridiem-text-color: #ec6321;
}
// dark mode cercle react-timepicker
body.dark .css-1kobbjs {
    background: #686363 !important;
}
body.dark .css-tqvze-ClockWrapper {
    background: #686363 !important;
}
body.dark .css-1yghxni {
    background: #686363 !important;
}
// dark mode clockHand react-timepicker
body.dark .css-16j6ljo-ClockHand {
    fill: #eb6321;
}
body.dark .css-hr2d4 {
    fill: #eb6321;
}

// date Picker dark mode  antd
body.dark .ant-picker-panel-container {
    background-color: #686363;
    border-color: #686363;
}
body.dark .ant-picker-panel-container .ant-picker-panel-focused {
    border-color: #686363;
}
body.dark .ant-picker-header-view {
    color: white;
}
body.dark .ant-picker-body {
    color: white;
}
body.dark .ant-picker-content th {
    color: white;
}
body.dark .ant-picker-cell.ant-picker-cell-disabled {
    color: white;
    opacity: 0.3;
}
body.dark .ant-picker-cell {
    color: #ec6321;
}
body.dark .ant-picker-cell-in-view,
body.dark .input-faker {
    color: white;
}
body.dark .ant-picker-cell-in-view:hover {
    color: #ec6321;
}
// end date Picker dark mode  antd

body.dark h1,
body.dark h2,
body.dark h3,
body.dark h4,
body.dark h5,
body.dark h6,
body.dark p,
body.dark strong,
body.dark .ant-upload.ant-upload-drag p.ant-upload-text,
body.dark .ant-upload.ant-upload-drag p.ant-upload-hint,
body.dark .ant-table-thead > tr > th,
body.dark .ant-table,
body.dark .ant-slider-mark-text,
body.dark span,
body.dark .ant-select-item,
body.dark .breadcrumbPath,
body.dark .ant-modal-title,
body.dark input[type="text"],
body.dark .ant-select-selection-search-input,
body.dark .ant-select-selector,
body.dark .scrollable-description *,
body.dark li,
body.dark .ant-collapse-content *,
body.dark .ql-snow.ql-toolbar *,
body.dark .ql-container *,
body.dark .ant-result-title,
body.dark .erd-address-field,
body.dark textarea,
body.dark select,
body.dark .extension-login_noExtensionButtonTitle,
.ant-empty-normal {
    color: #ffffff;
}
body.dark .footer-menu-item {
    color: #ffffff !important;
}

body.dark .ant-upload.ant-upload-drag,
body.dark .ant-table-tbody > tr.ant-table-row:hover > td,
body.dark .loading-more,
body.dark .ant-dropdown-menu-item:hover,
body.dark .ant-table-thead > tr > th,
body.dark
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
body.dark .date-number-container,
body.dark .ant-select-selector,
body.dark .disabled-checkbox-container,
body.dark .ql-snow.ql-toolbar,
body.dark .ant-spin-container::after,
body.dark .ant-spin-container,
body.dark .ant-input-affix-wrapper,
body.dark input[type="text"],
body.dark .ql-container,
body.dark .erd-address-field,
body.dark textarea,
body.dark select,
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: transparent !important;
}

body.dark .back-icon,
body.dark .price-logo,
body.dark .egld-icon,
body.dark .editIcon,
body.dark .button-icon,
body.dark .extension-login_noExtensionButtonIcon,
body.dark .edit-icon-collection {
    filter: brightness(0) invert(1);
}
body.dark .option-button,
body.dark .see-more-trading-history-btn {
    background-color: transparent;
}
body.dark .scrollable-description {
    p {
        strong {
            color: #ffffff !important;
        }
        span {
            color: #ffffff !important;
        }
    }
}

.dapp-icon-help-center {
    width: 40px;
    height: 40px;
    margin-right: 5px;
}

body.dark .highlight {
    color: @primary-color;
}

body.dark
    .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 0px 1px 0px -10px;
}
body.dark .button-video {
    & span {
        color: black;
    }
}

.text-regular-font {
    font-family: RegularFont;
}
.centered-text {
    text-align: center;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
}

a:hover {
    color: @primary-color;
    text-decoration: underline;
}

h1 {
    font-size: calc(1.5rem + 1.4vw);
}

h2 {
    font-size: calc(1.1rem + 1vw);
}

h3 {
    font-size: calc(0.9rem + 0.7vw);
}

h4 {
    font-size: calc(0.6rem + 0.6vw);
}

h5 {
    font-size: calc(0.6rem + 0.4vw);
}

h6 {
    font-size: calc(0.5rem + 0.4vw);
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff;
    background: @primary-color;
    border-color: @primary-color;
}

.ant-btn-default:hover,
.ant-btn-default:focus {
    color: @primary-color;
    background: #fff;
    border-color: @primary-color;
}

.ant-input:focus,
.ant-input-focused {
    outline: 0;
    box-shadow: 0 0 0 0px white;
}
.ant-btn-dashed {
    color: @primary-color;
    background: transparent;
    border-color: @primary-color;

    &:hover {
        color: @primary-color;
        background: white;
        border-color: @primary-color;
    }
}
.ant-btn {
    border-radius: 13px;
}

.required-field {
    color: @primary-color;
}
a:link {
    text-decoration: none;
}

.green-color {
    color: @green-color !important;
}

.red-color {
    color: @red-color !important;
}

.primary-color {
    color: @primary-color !important;
}

.normal-color {
    color: @normal-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 !important;
}

.btn-check:focus + .btn,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem @info-color !important;
}

.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem @info-color !important;
}

.btn-primary {
    background-color: @primary-color !important;
    border-color: @primary-color !important;
}

.btn-check:focus + .btn,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem @info-color !important;
}

.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem @info-color !important;
}

.ant-message-notice-content {
    z-index: 10000;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

body.dark input:-webkit-autofill,
body.dark input:-webkit-autofill:hover,
body.dark input:-webkit-autofill:focus,
body.dark input:-webkit-autofill:active {
    -webkit-text-fill-color: #ffffff;
}

.button-toast-nofitication {
    display: flex;
    justify-content: center;
    align-items: center;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;