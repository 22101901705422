.edit-profile-page {
  margin: 15vh 7vw;
}

.edit-container {
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
}

.profile-vertical-tab {
  width: 20vw;
}

.optionCol .ant-select-item-option-content{
  padding: 10px;
}

.optionCol .ant-select-item-option-content:hover{
  background-color: @info-color;
}

.profile-tab {
  padding: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  border-radius: @border-radius-base;
  cursor: pointer;

  &:hover {
    background-color: @normal-color;
  }

  h4,
  h5,
  h6 {
    margin: 0;
  }
}

.profile-active-tab {
  background-color: @info-color;

  &:hover {
    background-color: @info-color;
  }
}

.part-title {
  padding: 0 20px;
}

.profile-tab-content {
  width: 55vw;
}

.profile-social-links-container {
  display: grid;
  grid-template-columns: 45% 45%;
  grid-gap: 10%;
  margin-bottom: 60px;
}

.profile-social-link {
  margin: 10px 0;
}
.link-label {
  margin-bottom: 20px;
}

.edit-profile-buttons-container {
  display: flex;
  justify-content: flex-end;
}

.profile-cancel-button-container {
  margin-right: 20px;
}

.success-notif {
  background-color: #41b15d;
}

.error-notif {
  background-color: #ff4b4b;
}

.notif-icon-holder {
  border-radius: 10px;
  margin: 0 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 3px 0 3px;
}

.notif-icon {
  width: 35px;
}

.edit-profile-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.logout-button {
  color: @red-color;
  border-color: @red-color;
  background-color: white;
  margin: 10px 0;
  display: flex;

  &:hover {
    color: white;
    background-color: @red-color;
    .logout-icon {
      background-image: url("../../../../../assets//images/logout icon white.svg");
    }
  }
}

.logout-icon {
  width: 25px;
  height: 25px;
  margin: 0 10px;
  background-image: url("../../../../../assets//images/logout icon.svg");
}

@media (max-width: 70rem) {
  .edit-container {
    flex-direction: column;
  }
  .profile-vertical-tab {
    display: flex;
    width: 100%;
    margin-bottom: 50px;
  }

  .profile-tab {
    padding: 10px 20px;
  }

  .profile-tab-content {
    width: 100%;
  }
}

@media (max-width: 55rem) {
  .profile-vertical-tab {
    display: none;
  }
}

@media (max-width: 40rem) {
  .profile-social-links-container {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 25rem) {
  .edit-profile-buttons-container {
    display: flex;
    flex-direction: column;
  }
  .profile-cancel-button-container {
    margin: 0 0 10px 0;
  }
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;