.item-details-page {
	margin: 15vh 7vw;
}

.item-details-header-text {
	line-height: 1.5rem;
	h1 {
		font-size: calc(1.1rem + 1vw);
	}
	h2 {
		font-size: calc(0.6rem + 0.4vw);
	}
}

.item-details-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.item-details-icon-buttons {
	display: flex;
	justify-content: space-between;
}

.item-details-container {
	width: 100%;
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
}

.item-details-content {
	width: 50vw;
}

.item-details-content-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.users-place {
	display: flex;
	justify-content: space-between;
	width: fit-content;
	flex-wrap: wrap;
	align-items: center;
	margin: 10px 0;
}

.item-stats {
	width: 150px;
	display: grid;
	grid-template-columns: 45% 45%;
	grid-gap: 10%;
	margin: 10px 0;
}

.detail-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin: 10px 0;
}

.detail-part {
	width: 45%;
	margin: 10px 0;
}

.item-details-second-part {
	margin: 50px 0;
}

.item-details-mobile-recent-items {
	display: none;
}

.item-details-tab-content-holder {
	height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.menu-row {
	display: flex;
}
.item-menu-icon {
	margin: 0 10px;
}

.collection-name-link {
	cursor: pointer;
}

.item-details-load {
	width: 100%;
	height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 80rem) {
	.item-details-second-part {
		display: none;
	}
	.rowItem {
		width: auto !important;
	}

	.item-details-mobile-recent-items {
		display: inline;
	}
	.item-details-tab-content-holder {
		height: fit-content;
	}
}

@media (max-width: 60rem) {
	.item-details-container {
		flex-direction: column;
	}

	.item-details-content {
		margin: 40px 0;
		width: 100%;
	}
}

@media (max-width: 30rem) {
	.item-details-header {
		flex-direction: column;
	}

	.item-details-content-header {
		flex-direction: column;
		align-items: center;
	}

	.detail-row {
		display: flex;
		flex-direction: column;
	}

	.detail-part {
		width: 100%;
	}
	.item-details-header-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;