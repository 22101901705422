.new-collection {
	display: flex;
	flex-direction: column;
	align-items: center;

	.items-collections {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: 60px;
	}
}
.text-new-collection {
	margin-bottom: 100px !important;
	margin-top: 55px !important;
}
.title-newcollection {
	margin-left: 115px !important;
	font-family: RegularFont;
	font-size: calc(1.2rem + 1.1vw);
}

@media (max-width: 1270px) {
	.new-collection .items-collections {
		justify-content: center !important;
	}
}
@media (max-width: 600px) {
	.text-new-collection {
		padding: 0 50px !important;
	}

	.collection-item {
		width: 280px !important;
		margin-right: 0 !important;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;