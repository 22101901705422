.collection-show-cover-container {
	width: 100%;
	height: calc(100px + 25vh);
	border-radius: 15px;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}
.title-invite {
	margin-left: 2px !important;
	/* margin-top: 9px !important; */
	font-weight: bold !important;
	padding-bottom: 24px;
	font-size: 15px;
}
.title-invite-private {
	margin-left: -1px !important;
	/* margin-top: 9px !important; */
	font-weight: bold !important;
	padding-bottom: 24px;
	font-size: 15px;
}

.buttons-accept-refuse-invitation {
	display: flex;
	justify-content: space-around;
}
.text-invite {
	font-size: 12px;
	text-align: justify;
}
.linkto-invite {
	font-size: 15px;
}
.edit-collection-button {
	position: absolute;
	bottom: 40px;
	right: 1%;
	display: flex;
}
.copier-link-invitation {
	width: 376px;
	height: 50px;
	background-color: #f7f7f7;
	margin: auto;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}
.button-profile-logout {
	text-align: center !important;
	background: rgba(172, 172, 172, 0.4) 0% 0% no-repeat padding-box !important;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px) !important;
	position: relative;
	padding: 10px !important;
	height: 40px !important;
	border-radius: 10px !important;
	display: flex;
	color: white !important;
	border: transparent !important;
	margin-top: 13px !important;
	align-items: center;
	width: 140px;
}
.logout-icon-profile {
	margin-top: -3px !important;
	width: 20px !important;
	height: 20px !important;
	background-image: url("../../../../../assets//images/profil-logout.svg") !important;
}
.text-logout-profile {
	margin-top: -5px !important;
	color: white !important;
	font-size: 16px;
}
.collection-show-cover-photo {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.select-option {
	width: 221px !important;
}

.collection-header-body {
	margin: -100px 3vw 50px 7vw;
}

.collection-options {
	display: flex;
}

.collection-show-photo-holder {
	display: flex;
	justify-content: center;
}

.collection-options-container {
	display: flex;
	justify-content: flex-end;
	margin-top: -40px;
}

.desc-space {
	text-align: center;
	display: flex;
	justify-content: center;
	margin-top: 30px;
}

.desc-limit {
	width: 50vw;
	padding: 20px 0;
	h1 {
		font-size: calc(0.7rem + 0.6vw);
	}
}

.collection-show-photo-container {
	width: 13vw;
	height: 13vw;
	border-radius: 6.5vw;
	border: 6px solid white;
	position: relative;
	overflow: hidden;
}

.collection-show-photo {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.edit-collection-buttons {
	position: absolute;
	bottom: 40px;
	right: 5%;
	display: flex;
}

.collection-profile-menu-item {
	display: flex;
	align-items: center;
}

.flag-icon {
	width: 15px;
	margin: 10px 10px;
}

@media (max-width: 70rem) {
	.edit-collection-buttons {
		flex-direction: column;
	}

	.collection-show-photo-container {
		width: 18vw;
		height: 18vw;
		border-radius: 9vw;
	}

	.collection-header-body {
		margin: -70px 3vw 50px 7vw;
	}
}
.copier-link {
	padding-right: 6px;
	width: 221px;
	padding-left: 17px;
	height: 42px;
	background-color: #f7f7f7;
	margin: auto;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.copier-link .icon-button-container {
	background-color: #f7f7f7;
	width: 23px;
	height: 26px;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 5px;
}

.copier-link-invitation .icon-button {
	height: 25px !important;
}

@media (max-width: 40rem) {
	.edit-collection-buttons {
		top: 10px;
		right: 3%;
	}

	.collection-show-photo-container {
		width: 150px;
		height: 150px;
		border-radius: 75px;
	}

	.collection-header-body {
		margin: -70px 3vw 50px 7vw;
	}
	.collection-options-container {
		display: flex;
		justify-content: center;
		margin-top: 10px;
	}

	.desc-limit {
		width: 95%;
	}
}
.reportCollection-title {
	margin-left: 100px !important;
	margin-bottom: 13px !important;
}
.reportCollection-description {
	font-size: 15px !important;
	text-align: -webkit-center;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;