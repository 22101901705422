.activity-main {
	width: 100%;
	font-size: 12px;
	z-index: 10;
	top: 95px;
	position: fixed;
	background: white;
}
#root > div > div.main-body > div > div.activity-main > div > div {
	display: flex;
	flex-wrap: wrap;
}
.optionSelect {
	margin-left: 20px;
}
.optionSelect > .activity-select {
	font-size: 16px;
}
.table-genesis .ant-spin-nested-loading .ant-spin-container {
	margin: 0 auto;
}
.activity-filter {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	max-width: 83%;
	margin: auto;
	z-index: 10;
}
#root > div > div.main-body > div > div.activity-main > div > div {
	display: block;
	flex-wrap: wrap;
}
.table-genesis .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-small {
	border: 2px solid #efeeee !important;
	margin-bottom: 90px;
	max-width: 100%;
}
.table-genesis .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-small .ant-table-container {
	overflow: auto;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.selectors-select {
	margin-top: 30px;
}
.current {
	margin-top: 71px;
}
.table-genesis .table-responsive {
	margin-top: 20px;
}

.home-link-activity {
	color: black !important;
	font-size: 16px;
}
.button-seeMore {
	display: flex;
	margin: auto;
}

.text-from-table {
	padding-left: 10px;
	color: @primary-color;
}
.text-from {
	color: @primary-color;
}
.table-genesis .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-small {
	// max-width: 83%;
	margin: auto 20px;
}
.ant-select-selection-item-remove {
	display: none !important;
}

.search-input {
	padding: 10px 14px;
}

.activity-option {
	align-self: flex-end;
	display: flex;
	flex-direction: column;
	width: 170px;
}
.img-item-genesis {
	height: 35px;
	width: 35px;
	margin-top: 0px;
	border-radius: 30px;
	margin-left: 30px;
}
.collection-text {
	margin-left: 8px;
}

.img-fromto {
	border-radius: 50px;
	width: 45px;
	height: 45px;
}
.ant-select-item {
	padding: 0 !important;
}
.ant-select-item-option-active:hover,
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: transparent !important;
	padding: 0 !important;
}
.ant-checkbox-wrapper:hover,
.ant-radio-wrapper:hover {
	background-color: @info-color !important;
}
.ant-checkbox-group-item {
	margin: 0 !important;
}
.ant-checkbox-group,
.ant-radio-group,
.ant-space {
	width: 100%;
}
.ant-checkbox-wrapper,
.ant-radio-wrapper {
	padding: 10px;
	border-bottom: 1px solid #eae9e8;
	width: 100%;
	font-size: 13px;
}
.ant-select-dropdown {
	padding: 0 !important;
	position: fixed;
}

.ant-table-thead
	> tr
	> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}
.activity-select {
	display: flex;
	flex-direction: column;
}

.activity-main .ant-space-vertical {
	flex-direction: column;
}

.activity-select {
	color: @primary-color;
	margin-left: 26px;
}

.ant-table.ant-table-small {
	border: 2px solid #efeeee;
}

table > thead .text-left-artist {
	padding: 25px;
	padding-left: 48px !important;
}

.checkbox-activity {
	font-weight: bold;
	font-size: 12px;
	width: 180px;
	overflow-x: hidden !important;
}
.checkbox-collection {
	font-weight: bold;
	font-size: 12px;
	width: 221px !important;
	overflow-x: hidden !important;
}

.item-flex {
	margin: auto 0px;
}
.img-collection {
	width: 25px;
	height: 25px;
	border-radius: 5px;
}
.img-collection ::before {
	display: none;
}
.text-collection {
	margin-left: 10px;
}

.ant-checkbox-group-item {
	display: flex !important;
	line-height: 1.5715 !important;
}

.ant-checkbox:nth-child(1) {
	top: 4px;
}
.ant-checkbox + span {
	font-size: small;
}

.ant-pagination.mini.ant-table-pagination.ant-table-pagination-right {
	opacity: 0 !important;
}
.ant-table-small .ant-table-thead > tr > th {
	background-color: transparent;
}
.site-page-header-ghost-wrapper {
	padding: 24px;
	background-color: #f5f5f5;
}

.ContentPage {
	background-image: url("../../../assets/images/bggenesis.webp");
	background-size: cover;
	background-repeat: no-repeat;
	padding: 50px;
	text-align: center;
	min-height: 100vh;
	h1 {
		font-size: calc(1.5rem + 1.5vw);
	}
}

.ContentPage .titre,
.ContentPage .sousTitre {
	color: #ffffff;
	font-family: Montserrat;
	font-weight: lighter;
}

.placeLeft {
	display: flex;
	justify-content: center;
	margin-bottom: 35px;
}
.placeLeft div {
	background-color: #c8adba;
	padding: 10px;
	border-radius: 5px;
	font-family: BoldFont;
	text-transform: capitalize;
}
.discover {
	margin-bottom: 35px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	h4 {
		color: #797979 !important;
		font-family: RegularFont;
		font-size: calc(0.6rem + 0.6vw);
	}
	h5 {
		padding-top: 5px;
		font-family: BoldFont;
		font-size: calc(0.45rem + 0.45vw);
		width: 70vw;
	}
}
.discoverTitre {
	text-transform: uppercase;
	color: #ec6321 !important;
	padding: 7px;
	font-size: calc(0.9rem + 0.7vw);
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
	font-family: BoldFont;
	font-size: calc(0.5rem + 0.5vw);
}

.ArtistInfo {
	display: flex;
	align-items: center;
}
.key-artist {
	font-family: BoldFont;
	font-size: calc(0.4rem + 0.4vw);
	margin-left: 20px;
}
.no-data {
	font-family: BoldFont;
	font-size: calc(0.4rem + 0.4vw);
	margin-left: 20px;
	margin-top: 5px;
}
.fullnameArtists {
	cursor: pointer;
	margin: 0px 10px;
	color: #ec6321;
	font-family: BoldFont;
	font-size: calc(0.4rem + 0.4vw);
	text-transform: capitalize;
}
.watch-spotlight {
	font-family: BoldFont;
	font-size: calc(0.35rem + 0.35vw);
	padding-left: 10px;
}
.item-media {
	margin: auto 3px;
	cursor: pointer;
}

.nominated-list-bloc {
	margin-top: 30px;
	h6 {
		padding-bottom: 15px;
		font-family: BoldFont;
		font-size: calc(0.5rem + 0.5vw);
	}
}
.collection-avatar {
	width: 28px;
	height: 29px;
	border-radius: 10px;
	margin-right: -5px;
}
.ant-avatar.ant-avatar-circle {
	border-color: transparent;
	width: 29px;
	height: 29px;
	font-family: BoldFont;
	font-size: calc(0.4rem + 0.4vw);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
}
.icon-heart {
	width: 17px;
}
.back-button-genesis {
	width: fit-content;
	color: white !important;
	margin-top: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	h4 {
		margin: 0;
	}
}

.back-icon-genesis {
	margin: 0 10px;
	width: 2vw;
	filter: brightness(0) invert(1);
}
.back-text-genesis {
	color: white !important;
}

.spotlight-prodcast-links {
	cursor: pointer;
	color: black;
	margin: 0;
}

@media screen and (max-width: 670px) {
	.ContentPage {
		padding: 15px;
	}
	.ant-card-body {
		padding: 10px;
	}
}

@media screen and (max-width: 447px) {
	.ant-space-item .checkbox-activity {
		width: 140px !important;
	}
	.table-genesis .ant-spin-nested-loading .ant-spin-container .ant-table.ant-table-small {
		margin: 0 !important;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;