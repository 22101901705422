.collection-item {
  width: 350px;
  padding: 28px 2vw;
  border: 2px solid @normal-color;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 20px;
  height: 380px;

  .image-collection {
    width: 150px;
    height: 150px;
    background-color: @info-color;
    margin: 47px 0;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .existed-collection-image {
    width: 45%;
    height: 45%;
    object-fit: contain;
  }

  .desc-text {
    width: 100%;
    text-align: center;
    font-size: calc(0.7rem + 0.3vw);
  }

  &:hover {
    border: 2px solid @primary-color;
    cursor: pointer;
  }
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;