.explore-top {
	margin: 0 70px;
}
.breadcrumbPath {
	color: black;
}
.primary-color {
	color: @primary-color;
}
.explore-titel-filter {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 10px;
}
.explore-titel {
	display: flex;
	align-items: flex-end;
	h1 {
		font-size: calc(0.7rem + 0.6vw);
	}
}
.self-container .ant-col.ant-col-8 {
	max-width: 300px !important;
}
.empty_data img {
	width: 50%;
}

.explore-loading-container {
	width: 100%;
	height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.explore-result {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	width: 200px;
	margin: 0 5px;
	right: 0px;
}
.filter-option {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.explore-select {
	margin: 0 15px;
}
.img-notfound {
	display: flex;
	justify-content: center;
	padding-top: 54px;
}
.explore-range {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 5px;
	width: 200px;
}
.explore-slider {
	height: 10px;
}
.slider {
	padding: 0;
	margin: 10 1 10;
}
.scroll-to-top {
	display: flex;
	justify-content: center;
	z-index: 3;
	position: fixed;
	bottom: 2vh;
	right: 70px;
	// color: @primary-color;
	background-color: white;
}
.arrow-icon {
	align-self: center;
	height: 20px;
}
.anticon-arrow-up {
	width: 1.5em;
	height: 1.5em;
}
.anticon-arrow-up svg {
	width: 1.5em;
	height: 1.5em;
}
.spinner {
	margin: 100px auto 0;
	width: 70px;
	text-align: center;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: @primary-color;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
	background-color: @primary-color;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
	background-color: @primary-color;
}
.spinner-loader-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
.ant-slider-handle.ant-slider-handle-1 {
	background-color: @primary-color !important;
	border: white !important;
}
.ant-slider-handle.ant-slider-handle-2 {
	background-color: @primary-color !important;
	border: white !important;
}
.ant-slider-track {
	background-color: @primary-color !important;
}

.ant-select-arrow :active {
	transform: rotate(180deg);
}

@media (max-width: 990px) {
	.explore-slider {
		height: 20px !important;
	}
	.explore-result {
		width: 110px;
	}
}
@media (max-width: 890px) {
	.explore-slider {
		height: 20px !important;
	}
	.explore-titel-filter {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.filter-option {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;