.attributes-container {
  border-radius: 15px;
  margin: 10px 0 20px 0;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.red-border {
  border: 2px dashed #fd4b4b;
}

.green-border {
  border: 2px dashed #18a574;
}

.primary-border {
  border: 2px dashed @primary-color;
}

.empty-attribute-container {
  border-radius: 10px;
  padding: 5px 1vw;
  width: fit-content;
  margin: 0 1vw 20px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h5 {
  margin-bottom: 0;
}

.popular-attribute-container {
  background-color: white;
  border: 2px solid @primary-color;
  color: @primary-color;

  &:hover {
    background-color: @info-color;
  }
}

.mandatory-attribute-container {
  border: 2px solid #ff4b4b;
  background-color: #ffd6d6;
  color: #ff4b4b;
}

.suggested-attribute-container {
  border: 2px solid #18a574;
  background-color: #ddfff4;
  color: #18a574;
}

.attribute-side-icon {
  color: @primary-color;
  margin-left: 20px;
}

.attribute-close-icon {
  padding: 5px 5px 5px 15px;
}

.add-attribute-holder {
  border-radius: 10px;
  padding: 5px 1vw;
  width: fit-content;
  margin: 0 1vw 20px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-attribute-icon {
  margin-right: 10px;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;