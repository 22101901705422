.tab-header-mobile {
	border: 1px solid @normal-color;
	border-radius: 10px;
	padding: 10px;
	width: 100%;
	justify-content: center;
	// display: none;
	h6 {
		margin: 0;
	}
}

.tab-mobile {
	padding: 10px;
	display: flex;
	justify-content: center;
	cursor: pointer;
	border-radius: @border-radius-base;
	&:hover {
		background-color: @normal-color;
	}
}

.mobile-menu-dropdown {
	padding: 10px;
	border-radius: @border-radius-base;
	margin: 0 10px;
	background-color: @normal-color;
}

@media (max-width: 1300px) {
	
	.ant-dropdown {
	  position: relative !important;
	  width: fit-content !important;
	//   z-index: 9 ;
	}
	.mobileDropdown {
	  z-index: 9 !important;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;