.crypto-animal-container {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../../../assets/images/cryptoAnimalBG.svg") no-repeat;
  background-size: 100vw auto;

  /* Set a specific height */
  // height: 50%;
}

.crypto-animal-content {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crypto-animal-part {
  width: 50vw;
  display: flex;
  justify-content: center;
  text-align: center;
}

.custom-crypto-animal-card {
  padding: 1em 2.5em;
  border-radius: 5px;
}

.crypto-animal-link {
  &:hover {
    text-decoration: underline;
  }
}

.crypto-animals-btn {
  margin: 10px 0;
  padding: 5px 10px;
}

.crypto-animal-btn-container {
  margin-top: 10px;
}

@media (orientation: portrait) {
  body {
    background-size: auto 100vh;
  }
}

.card {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: fit-content;
}

.card-text {
  padding-bottom: 10px;
}

.custom-a-styling a:link {
  color: #fff;
  font-weight: 500;
}

.custom-a-styling a:visited {
  color: rgba(255, 255, 255, 0.8);
}

.custom-a-styling a:hover {
  color: #0efafe;
}
.landing-btn-container {
  position: absolute;
  right: 2%;
  top: 2%;
}

.btn-hero {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  border: 2px solid #fff0;
  font-weight: 300;
}

.btn-hero:hover {
  color: #fff;
  background-color: #ffffff30;
  border: 2px solid #0efafe;
}

.slider-mint{

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 30%;
  margin-top: 10px;
}
@media screen and (max-width: 1100px) {
  .crypto-animal-content {
    flex-direction: column;
    justify-content: space-around;
  }
  .crypto-animal-container {
    background-size: cover;
  }
  .custom-crypto-animal-card {
    padding: 4em 1.5em;
    width: 100%;
  }
  .crypto-animal-part {
    width: 70vw;
  }
}

@media screen and (max-width: 700px) {
  .brand-img {
    width: 75%;
  }
}

@media screen and (max-width: 500px) {
  .crypto-animal-part {
    width: 80vw;
  }
}

@media screen and (max-width: 400px) {
  .brand-img {
    width: 80%;
  }
  .custom-crypto-animal-card {
    padding: 10px;
  }
  .crypto-animal-content {
    justify-content: space-evenly;
  }
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;