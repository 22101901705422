.item-tab-container {
	width: 100%;
}

.item-tab-header {
	border: 1px solid @normal-color;
	border-radius: 10px;
	padding: 10px;
	width: 100%;
	display: grid;
	grid-template-columns: 18% 18% 18% 16% auto;
	grid-gap: 2%;

	h6 {
		margin: 0;
	}
}
.item-table {
	padding: 0px !important;
}

.item-tab {
	padding: 10px 0;
	width: 100%;
	display: flex;
	justify-content: center;
	cursor: pointer;
	border-radius: @border-radius-base;
	&:hover {
		background-color: @normal-color;
	}
}

.active-tab {
	background-color: @info-color;
	color: @primary-color;

	&:hover {
		background-color: @info-color;
		color: @primary-color;
	}
}

.item-tab-content {
	margin: 15px;

	h6 {
		margin-bottom: 0;
	}
}

.item-details-properties {
	display: grid;
	grid-template-columns: 23% 23% 23% 23%;
	grid-gap: 2%;
	width: 100%;
}

.item-details-property {
	padding: 10px;
	background-color: @info-color;
	border: 2px solid @primary-color;
	border-radius: @border-radius-base;
	text-align: center;
	height: fit-content;
}

.info-holder {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 2px solid @normal-color;
}

.item-details-mobile-tab {
	display: none;
}

.scrollable-description {
	height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
}

.table-egld-logo {
	width: 15px;
	margin: 0 5px;
}
.block-table-trading-history {
	position: relative;
}

.trading-pagination-button {
	display: flex;
	justify-content: center;
}
.see-more-trading-history-btn {
	position: absolute;
	bottom: 0%;
}

.offer-tab-button {
	width: 25px;
	margin: 5px;
	cursor: pointer;
}

@media (max-width: 40rem) {
	.item-tab-header {
		display: none;
	}
	.item-details-properties {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.item-details-property {
		margin: 10px 0;
	}
	.item-details-mobile-tab {
		display: flex;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;