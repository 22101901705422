.light-button {
	width: 100%;
	color: @primary-color;
	background-color: @info-color !important;
	border-color: @info-color !important;

	&:hover {
		color: @primary-color;
		background-color: @info-color !important;
		border-color: @info-color !important;
	}

	&:focus {
		color: @primary-color;
		background-color: @info-color !important;
		border-color: @info-color !important;
	}
}

.option-button {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	border: 2px solid #e8e8e8;
	border-radius: 10px;
	height: fit-content;
	margin: 10px 0;
}

.option-button:hover {
	border: 2px solid @primary-color;
	background-color: #fffdfc;
	cursor: pointer;
}

.text-container {
	text-align: left;
	margin: 15px 20px 10px 0;
}

.button-icon-container {
	margin: 20px;
	width: 40px;
}

.button-icon {
	width: 100%;
	height: 100%;
}

.description-text {
	margin-top: -10px;
	line-height: 1.2em;
}
.dapp-core-component__main__btn-primary {
	background-color: @primary-color !important;
	border-color: @primary-color !important;
}

a,
.dapp-core-component__main__link-style {
	color: @primary-color !important;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;