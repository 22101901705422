.home-background {
	margin-top: -100px;
	height: 100vh;
	background: url("../../../assets//images/home background.png") center/cover no-repeat border-box;
	position: relative;
	display: flex;
	justify-content: center;
}

.feature-collection .small-images-container {
	margin: -5px !important;
}
.home-title {
	line-height: 3.6rem;
}
.image-collection-profile {
	height: 60px !important;
}
.home-description {
	margin: 20px 0;
	line-height: 1rem;
	h4 {
		font-weight: bold;
	}
}

.background-title {
	display: flex;
}

.home-button {
	width: 170px;
	margin-top: 25px;
	margin-right: 2vw;
}

.orange-outline-button {
	border: 2px solid @primary-color;
	color: @primary-color;
}

.home-content {
	margin-top: 150px;
	width: 80%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.home-title {
	color: @primary-color;
}

.part-container {
	margin: 100px 7vw;
}

.feature-collection {
	width: 100%;
}

.big-image-container {
	border-radius: 30px;
}

.big-image {
	width: 100%;
	height: 220px;
	object-fit: cover;
}

.small-images-container {
	margin: 20px 0;
}

.small-image-container {
	border-radius: 20px;
	height: 100px;
}

.img-margin {
	margin-right: 1.5vw;
}

.small-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.img-hover-zoom {
	width: 100%; /* [1.1] Set it as per your need */
	overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
	transition: transform 1s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
	transform: scale(1.2);
}

.collections-grid {
	margin: 50px 0;
}

.most-like-recent-grid {
	margin: 50px 0;
	display: flex;
	justify-content: space-around;
}

.collection-owner-holder {
	display: flex;
	align-items: center;
}

.featured-collection-mobile-version {
	display: none;
}

.collections-button-container {
	display: flex;
	justify-content: center;
	margin: 50px 0 0 0;
}

.collections-button {
	border: 3px solid @primary-color;
	color: @primary-color;
	padding: 9px 35px !important;
}

.collections-button:hover {
	border: 3px solid transparent;
	background-color: @primary-color;
	color: white;
}

.orange-cards-container {
	display: flex;
	justify-content: space-between;
}

.recent-items-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: 20px 0;
}

.mobile-recent-items {
	display: none;
}

.mobile-auctions-part {
	display: none;
}

.orange-mobile-cards {
	display: none;
}
.description-text-headline {
	padding-top: 15px;
}

@media (max-width: 80rem) {
	.home-content {
		height: fit-content;
		margin-top: 150px;
		width: 80%;
		flex-direction: column-reverse;
		align-items: center;
	}
	.home-text-container {
		text-align: center;
	}
	.home-title {
		line-height: 3rem;
	}
	.background-title {
		display: flex;
		justify-content: center;
	}
	.part-container {
		margin: 100px 7vw 0 7vw;
	}
	.home-background {
		height: fit-content;
	}
}

@media (max-width: 80rem) {
	.orange-cards-container {
		display: none;
	}
	.orange-mobile-cards {
		display: block;
	}
	.web-recent-items {
		display: none;
	}

	.mobile-recent-items {
		display: block;
	}

	.web-auctions-part {
		display: none;
	}

	.mobile-auctions-part {
		display: block;
	}
}

@media (max-width: 45rem) {
	.collections-grid {
		display: none;
	}
	.featured-collection-mobile-version {
		display: flex;
	}
	.feature-collection {
		width: 85vw;
	}

	.big-image {
		width: 85vw;
	}
	.collections-button-container {
		margin: 50px 0 0 0;
	}
	.most-like-recent-grid {
		display: none;
	}
}

@media (max-width: 25rem) {
	.part-container {
		margin: 50px 4vw 0 4vw;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;