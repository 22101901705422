.banner-show-cover-container {
  width: 100%;
  height: calc(50px + 8vh);
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.banner-show-cover-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.collection-banner-global-container {
  margin: 20px 0;
}

.propositions-container {
  margin: 20px 0;
}

.banner-proposition-container {
  width: 100%;
  height: calc(50px);
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  border: 3px solid transparent;
  cursor: pointer;
}

.highlighted-proposition {
  border: 3px solid @primary-color;
}

.checked-button-container {
  position: absolute;
  top: 15%;
  right: 11%;
}

.upload-banner-button {
  width: 100% !important;
  height: calc(50px);
  border-radius: 15px;
  border: 2px dashed @primary-color;
  cursor: pointer;
  // background-color: @medium-gray-color;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  &:hover {
    border: 2px dashed @primary-color;
  }
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;