.custom-select-wrapper {
	position: relative;
	user-select: none;
	width: 150px;
	height: 50px;
}
.custom-select {
	border-color: transparent;
	color: white;
	padding: 0px 0px 0px 0px;
	background: none;
	border: none;
	font-family: RegularFont;
}
.custom-select__trigger {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: @border-radius-base;
	padding: 0 10px;
	width: 150px;
	height: 50px;
	font-family: RegularFont;
	background-color: @primary-color;
	color: white;
	cursor: pointer;
}
.custom-options {
	position: absolute;
	display: block;
	left: 0;
	right: 0;
	background: white;
	width: 150px;
	transition: all 0.5s;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	z-index: 2;
	box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
		rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
	border-radius: @border-radius-base;
	list-style-type: none;
	overflow: hidden;
}
.custom-select-open {
	opacity: 1;
	visibility: visible;
	pointer-events: all;
}
.custom-option {
	position: relative;
	display: block;
	padding: 0 22px;
	color: black;
	line-height: 40px;
	cursor: pointer;
	transition: all 0.5s;
}
.custom-option:hover {
	cursor: pointer;
	background-color: @normal-color;
}
.custom-option.selected {
	color: @primary-color;
	background-color: @info-color;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;