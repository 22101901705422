.checkbox-container {
  padding: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;
}

.checked-checkbox-container {
  border: 2px solid @primary-color;
  background-color: @info-color;
}

.unchecked-checkbox-container {
  border: 2px solid @normal-color;
  background-color: white;
}

.disabled-checkbox-container{
  padding: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor:not-allowed;
  margin: 10px 0;
  border: 2px solid @medium-gray-color;
  background-color: @light-gray-color;
}

.checked-icon {
  width: 20px;
  margin: 0 15px 0 10px;
}

.empty-check-circle {
  width: 21px;
  height: 21px;
  margin: 0 15px 0 10px;
  border-radius: 10px;
  border: 1px solid @normal-color;
}

.check-desc {
  padding-top: 10px;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;