.slider-container {
  width: 30vw;
  margin: 50px 0;
}

.tall-image {
  width: 11.5vw;
}

.normal-image {
  width: 16.5vw;
}

.shadowed-1 {
  border-width: 60px;
  filter: drop-shadow(-25px -5px 9px rgba(0, 0, 0, 0.2));
}

.shadowed-2 {
  border-width: 60px;
  filter: drop-shadow(5px -25px 9px rgba(0, 0, 0, 0.2));
  cursor: pointer;

}

.shadowed-3 {
  border-width: 60px;
  filter: drop-shadow(-5px 25px 9px rgba(0, 0, 0, 0.2));
}

.shadowed-4 {
  border-width: 60px;
  filter: drop-shadow(25px 5px 9px rgba(0, 0, 0, 0.2));
  cursor: pointer;

}

@media (max-width: 80rem) {
  .slider-container {
    width: 100%;
  }
  .tall-image {
    width: 15vw;
  }

  .normal-image {
    width: 25vw;
  }
}

@media (max-width: 45rem) {
  .tall-image {
    width: 19vw;
  }

  .normal-image {
    width: 29vw;
  }

  .shadowed-1 {
    border-width: 40px;
    filter: drop-shadow(-15px -5px 9px rgba(0, 0, 0, 0.2));
  }

  .shadowed-2 {
    border-width: 40px;
    filter: drop-shadow(5px -15px 9px rgba(0, 0, 0, 0.2));
  }

  .shadowed-3 {
    border-width: 40px;
    filter: drop-shadow(-5px 15px 9px rgba(0, 0, 0, 0.2));
  }

  .shadowed-4 {
    border-width: 40px;
    filter: drop-shadow(15px 5px 9px rgba(0, 0, 0, 0.2));
  }
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;