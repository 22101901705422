.btn-landing-page {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  border: 2px solid #fff0;
  font-weight: 300px;
  text-transform: capitalize;
  &:hover {
    color: #fff;
    background-color: #ffffff30;
    border: 2px solid #0efafe;
  }
}

.landing-crypto-animals-btn {
  margin: 10px 0;
  padding: 5px 10px;
  height: 40px;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;