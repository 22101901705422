.collection-container {
	margin: 15vh 5vw;

	.collection-content {
		width: 100%;
		.collection-text {
			margin-bottom: 40px;
			.title-collection {
				margin-bottom: 0;
			}
			h1 {
				font-size: calc(1.1rem + 1vw);
			}
			h2 {
				font-size: calc(0.6rem + 0.4vw);
			}
		}
	}
}

.form-buttons {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
}

.form-cancel-button {
	margin-right: 20px;
	margin-bottom: 10px;
}

.existingColTab .collection {
	margin: auto;
	grid-template-columns: auto auto auto auto !important;
}

.existingCollRow .ant-row {
	justify-content: space-between;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;