.ql-container {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: white;
  height: 100px;
}

.ql-snow.ql-container {
  border-color: @normal-color;
  border-width: 2px;
}

/* Snow Theme */
.ql-snow.ql-toolbar {
  display: block;
  background: @light-gray-color;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-color: @normal-color;
  border-width: 2px;
}

.editor-holder {
  margin: 20px 0;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;