.display-title {
	margin-left: 10px !important;
}
.user-display-container {
	display: flex;
	align-items: center;
	cursor: pointer;
}
@media screen and (max-width: 1500px) {
	.display-title {
		margin-left: 7px !important;
	}
}
@media screen and (max-width: 1200px) {
	.display-title {
		margin-left: 5px !important;
		font-size: 12px;
	}
}
@media screen and (max-width: 1100px) {
	.user-display-container {
		display: flex;
		text-align: center;
	}
}
@media screen and (max-width: 900px) {
	.user-display-container {
		display: flex;
		text-align: center;
	}
}
@media screen and (max-width: 800px) {
	.user-display-container {
		display: flex;
		flex-direction: column;
		text-align: center;
	}
	.ant-table.ant-table-middle .ant-table-title,
	.ant-table.ant-table-middle .ant-table-footer,
	.ant-table.ant-table-middle .ant-table-thead > tr > th,
	.ant-table.ant-table-middle .ant-table-tbody > tr > td,
	.ant-table.ant-table-middle tfoot > tr > th,
	.ant-table.ant-table-middle tfoot > tr > td {
		width: 24vw;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;