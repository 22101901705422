.collection-profile-tab {
  width: 124%;
  display: grid;
  grid-template-columns: 19% 19% 19% 19% auto;
  grid-gap: 2%;
}


@media screen and (max-width: 1950px){
  .img-container-items-profile .collection-profile-add-item-new{

    width: 324px;
   
}
  
.menu-twil .collection{

  width: 106%;
  display: grid;
  grid-template-columns: 24% 24% 24% 0% auto;
  grid-gap: 1%;
  margin-bottom: 24px;
  margin-left: 43px;

  
}
}
@media screen and (max-width: 1680px){
  .menu-twil .collection{
  width: 103%;
  display: grid;
  grid-template-columns: 24% 24% 24% 0% auto;
  grid-gap: 1%;
  margin-bottom: 24px;
  margin-left: 14px;
  }
}

.collection {
  width: 90%;
  display: grid;
  grid-template-columns: 23% 23% 23% auto auto;
  grid-gap: 1%;
  margin-bottom: 50px;
}

.collection .ant-btn.ant-btn-primary.button-collection-tab{
  // width: 170px!important;
  text-align: center;
}
.collection .ant-btn.ant-btn-primary.button-collection-tab, .checkbox-activity {
  // width: 175px !important;
  padding: 0;
  font-size: 15px;
}

@media (max-width: 85rem) {
  .collection-profile-tab {
    display: none;
  }
  .collection {
    display: none;
  }
}
.collection .ant-btn.ant-btn-text.button-collection-tab.secondary-button.text-regular-button{
// width:221px !important;
text-align: center !important;
font-size: 17px;
padding: 5px;
}
.collection .ant-btn.ant-btn-primary.button-collection-tab{

  text-align: center !important;
}

.collection-profile-not-connect .ant-btn{
 padding:0px
}
@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;