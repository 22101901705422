.item-photos-global-container {
	width: 27vw;
	display: grid;
	grid-template-columns: 6vw auto;
}

.item-photos-global-container-one-image {
	width: 27vw;
	display: grid;
	grid-template-columns: 0px auto;
}

.item-details-image-container {
	width: 27vw;
	height: 27vw;
	border-radius: 25px;
	position: relative;
	overflow: hidden;
	.ant-image-img {
		width: 27vw;
		height: 27vw;
	}
}
.item-details-video-container {
	width: 32vw;
	height: 30vw;
	border-radius: 25px;
	position: relative;
	overflow: hidden;
	.ant-image-img {
		width: 27vw;
		height: 27vw;
	}
}
video {
	width: 100% !important;
	border-radius: 25px;
}
.item-details-video {
	width: 32vw !important;
	height: auto !important;
}

.item-video-button {
	margin-left: 5px;
}

.item-upload-box-container {
	width: 27vw;
	height: 27vw;
}

.item-details-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.mini-photos-container {
	width: 5vw;
	height: 5vw;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	margin-bottom: 1vw;

	&:hover {
		border: 2px solid @primary-color;
	}
}

.mini-photos-container-selected {
	border: 2px solid @primary-color;
}

.mini-photos-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.photos-spinner {
	position: absolute;
	top: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 1;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.item-add-spinner {
	position: absolute;
	top: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 1;
	width: 100%;
	height: 110%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
}

.add-item-photo-button {
	background-color: @info-color;
	width: 5vw;
	height: 5vw;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.add-item-photo-button-icon {
	width: 2vw;
}
.lock-item-photo-button-icon {
	width: 5vw;
	height: 5vw;
	cursor: pointer;
}
.lock-item-photo-button {
	margin-top: 10px;
	background-color: @info-color;
	width: 5vw;
	height: 5vw;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.photo-item-photo-button {
	background-color: @info-color;
	width: 5vw;
	height: 5vw;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.play-block-item {
	position: relative;
}

.delay1 {
	animation: waves 2.5s linear infinite;
	animation-delay: .1s;
}
.delay2 {
	animation: waves 2.5s linear .7s forwards infinite;
}
.delay3 {
	animation: waves 2.5s linear 1.3s forwards infinite;
}
.delay4 {
	animation: waves 2.5s linear 1.9s forwards infinite;
}

.play-block-icon {
	position: absolute;
	top: 42%;
	left: 41%;
	cursor: pointer;
	z-index: 1;
	height: 80px;
	width: 80px;
}

.circle {
	display: block;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	background: #ffdfd1;
	margin: 10px;
	transition: 5s ease;
	position: absolute;
	top: 42%;
	left: 41%;
}

// waves
@-webkit-keyframes waves {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(4);
		opacity: 0;
	}
}

@keyframes bloop {
	0% {
		transform: scale3d(1, 1, 1);
	}

	30% {
		transform: scale3d(1.25, 0.75, 1);
	}

	40% {
		transform: scale3d(0.75, 1.25, 1);
	}

	50% {
		transform: scale3d(1.15, 0.85, 1);
	}

	65% {
		transform: scale3d(.95, 1.05, 1);
	}

	75% {
		transform: scale3d(1.05, .95, 1);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}
@media (max-width: 80rem) {
	.photo-item-photo-button {
		background-color: @info-color;
		width: 5vw;
		height: 5vw;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.responsive-btn-item-video {
		font-size: 14px;
	}
	.item-details-video-container {
		width: 34vw;
		height: 33vw;
		border-radius: 25px;
		position: relative;
		overflow: hidden;
		.ant-image-img {
			width: 27vw;
			height: 27vw;
		}
	}
	.item-details-video {
		width: 34vw !important;
		height: auto !important;
	}
}
@media (max-width: 70rem) {
	.responsive-btn-item-video {
		font-size: 13px;
		padding: 25px;
		display: inline-flex;
		align-items: center;
	}
}
@media (max-width: 60rem) {
	.item-details-video-container {
		width: 45vw;
		height: 33vw;
		border-radius: 25px;
		position: relative;
		overflow: hidden;
		.ant-image-img {
			width: 27vw;
			height: 27vw;
		}
	}
	.item-details-video {
		width: 45vw !important;
		height: auto !important;
	}
}

@media (max-width: 50rem) {
	.item-photos-global-container {
		width: 50vw;
		grid-template-columns: 12vw auto;
	}

	.item-photos-global-container-one-image {
		width: 50vw;
	}

	.item-details-image-container {
		width: 50vw;
		height: 50vw;
		.ant-image-img {
			width: 100%;
			height: 100%;
		}
	}

	.item-upload-box-container {
		width: 50vw;
		height: 50vw;
	}

	.mini-photos-container {
		width: 10vw;
		height: 10vw;
		margin-bottom: 2vw;
	}

	.add-item-photo-button {
		width: 10vw;
		height: 10vw;
	}

	.add-item-photo-button-icon {
		width: 4vw;
	}
	.lock-item-photo-button-icon {
		width: 10vw;
		height: 10vw;
		cursor: pointer;
		border-radius: 8px;
	}
	.photo-item-photo-button {
		background-color: @info-color;
		width: 10vw;
		height: 10vw;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.lock-item-photo-button {
		margin-top: 10px;
		background-color: @info-color;
		width: 10vw;
		height: 10vw;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.lock-item-photo-button-icon {
		width: 10vw;
		height: 11vw;
		cursor: pointer;
	}
	.item-details-video-container {
		width: 50vw;
		height: 40vw;
		border-radius: 25px;
		position: relative;
		overflow: hidden;
		.ant-image-img {
			width: 27vw;
			height: 27vw;
		}
	}
	.item-details-video {
		width: 50vw !important;
		height: auto !important;
	}
	.responsive-btn-item-video {
		font-size: 13px;
		padding: 20px;
		display: inline-flex;
		align-items: center;
	}
}

@media (max-width: 35rem) {
	.item-photos-global-container {
		width: 70vw;
		grid-template-columns: 16vw auto;
	}

	.item-photos-global-container-one-image {
		width: 70vw;
		align-self: center;
	}

	.item-details-image-container {
		width: 70vw;
		height: 70vw;
	}

	.item-upload-box-container {
		width: 70vw;
		height: 70vw;
	}

	.mini-photos-container {
		width: 13vw;
		height: 13vw;
		margin-bottom: 2vw;
	}

	.add-item-photo-button {
		width: 13vw;
		height: 13vw;
	}

	.add-item-photo-button-icon {
		width: 4vw;
	}
	.lock-item-photo-button-icon {
		width: 13vw;
		height: 13vw;
		cursor: pointer;
		border-radius: 8px;
	}
	.photo-item-photo-button {
		background-color: @info-color;
		width: 13vw;
		height: 13vw;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.lock-item-photo-button {
		margin-top: 10px;
		background-color: @info-color;
		width: 13vw;
		height: 13vw;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.lock-item-photo-button-icon {
		width: 13vw;
		height: 14vw;
		cursor: pointer;
	}
	.item-details-video-container {
		width: 70vw;
		height: 70vw;
		border-radius: 25px;
		position: relative;
		overflow: hidden;
		.ant-image-img {
			width: 27vw;
			height: 27vw;
		}
	}
	.item-details-video {
		width: 70vw !important;
		height: auto !important;
	}
	.responsive-btn-item-video {
		font-size: 13px;
		padding: 15px;
		display: inline-flex;
		align-items: center;
	}
}

@media (max-width: 25rem) {
	.item-details-video-container {
		width: 90vw;
		height: 90vw;
		border-radius: 25px;
		position: relative;
		overflow: hidden;
		.ant-image-img {
			width: 27vw;
			height: 27vw;
		}
	}
	.item-details-video {
		width: 90vw !important;
		height: auto !important;
	}
	.responsive-btn-item-video {
		font-size: 13px;
		padding: 15px;
		display: inline-flex;
		align-items: center;
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;