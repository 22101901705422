// tabCollectionProfile Style

.profile-tab-loading-content {
	width: 100%;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.spacetop {
	margin-top: 26px;
}

.activity-select {
	color: #ec6321;
	margin-left: 0 !important;
}

.col-style {
	margin: 20px;
}

.col-add {
	background-color: #ffdfd1;
	border-radius: inherit;
	border: 3px dashed #ec6321;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	text-align: center;
}
.col-add h5 {
	color: #ec6321;
}

.ant-col-9 {
	max-width: 28% !important;
	min-height: 400px !important;
}

// .ant-row {
//   width: 100%  !important;
// }

.big-collection > .ant-col {
	margin: 10px 22px;
}

.button-seeMore {
	margin-top: 10px;
}

.ant-select-dropdown {
	padding: 0 !important;
	position: fixed;
}

.ant-radio-wrapper:hover {
	background-color: #ffdfd1 !important;
}

.ant-radio-wrapper {
	padding: 10px;
	border-bottom: 1px solid #eae9e8;
	width: 100%;
	font-size: 13px;
}

.rc-virtual-list {
	position: relative;
}

.ant-select-item {
	padding: 0 !important;
}

.ant-select-selection-item-remove {
	display: none !important;
}

.ant-select-item-option-active:hover,
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: transparent !important;
	padding: 0 !important;
}

.ant-checkbox-wrapper:hover,
.ant-radio-wrapper:hover {
	background-color: @info-color !important;
}

.ant-checkbox-group-item {
	margin: 0 !important;
}

.ant-checkbox-group,
.ant-radio-group,
.ant-space {
	width: 100%;
}

.ant-checkbox-wrapper,
.ant-radio-wrapper {
	padding: 10px;
	border-bottom: 1px solid #eae9e8;
	width: 100%;
	font-size: 13px;
}

.ant-select-dropdown {
	padding: 0 !important;
	position: relative !important;
	z-index: 9 !important;
}

.ant-select-item-option-state {
	display: none;
}

.tab-header-mobile {
	width: 90%;
	margin: 0 auto;
	justify-content: center;
}

.img-hover-zoom {
	margin: 10px 0;
	&:hover .delay-item1 {
		animation: waves 2.5s linear;
		animation-delay: .1s;
	}

	&:hover .delay-item2 {
		animation: waves 2.5s linear .7s forwards;
	}

	&:hover .delay-item3 {
		animation: waves 2.5s linear 1.3s forwards;
	}

	&:hover .delay-item4 {
		animation: waves 2.5s linear 1.9s forwards;
	}
}

.play-block-icon-recent-item {
	position: absolute;
	top: 42%;
	left: 40%;
	cursor: pointer;
	z-index: 1;
	height: 60px;
	width: 60px;
}

.circle-recent-item {
	display: block;
	height: 55px;
	width: 55px;
	border-radius: 50%;
	background: #ffdfd1;
	margin: 10px;
	transition: 5s ease;
	position: absolute;
	top: 40%;
	left: 38%;
}

.filters {
	display: grid !important;
	grid-template-columns: 25% 25% 25% 25% !important;
	justify-content: center;
}

.item-details-load {
	width: 100%;
	height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.nodata {
}

// Old css

.collection-show-page {
	margin: 15vh 0;
}

.collection-show-header {
	margin: 0 4vw;
}

.collection-profile-mobile-tab {
	display: none;
}

.collection-profile-add-item {
	width: 100%;
	height: 100%;
	background-color: @info-color;
	border-radius: 20px;
	border: 3px dashed @primary-color;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.notif-collection {
	margin-bottom: 15px;
	background-color: #e6f7ff;
	border: 1px solid #91d5ff;
	border-radius: 11px;
	padding: 6px;
	align-items: center;
}

@media screen and(max-width:1920px) {
	.ant-alert.ant-alert-info.ant-alert-with-description.notif-collection {
		display: flex;
		align-items: center;
		margin-left: 15px;
		width: 1177px;
	}
}

.ant-alert-with-description .ant-alert-message {
	margin-bottom: 0px !important;
}
.message-info {
	margin-bottom: 0px !important;
}

.Link-info {
	color: #1890ff !important;
	text-decoration: underline !important;
}
.notif-collection.ant-alert-info .ant-alert-icon {
	color: white;
	background-color: #1890ff;
	border-radius: 20px;
}
.message-info {
	font-size: 15px;
}
.info-alert {
	color: #1890ff;
}

@media screen and (max-width: 2000px) {
	.big-collection {
		display: flex !important;
	}

	.profile-tab-right {
		max-width: 68% !important;
	}
	.select-profile-details {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		width: 1282px;
		margin-left: 0px;
	}
	.menu-twil {
		margin-left: 50px;
	}

	.menu-twil .collection {
		width: 106%;
		display: grid;
		grid-template-columns: 24% 24% 24% 0% auto;
		grid-gap: 1%;
		margin-bottom: 24px;
		margin-left: 29px;
	}
}

.profile-statistic {
	margin-top: 48px;
}

@media screen and (max-width: 1680px) {
	.big-collection {
		display: flex !important;
	}
	.ant-alert.ant-alert-info.ant-alert-with-description.notif-collection {
		display: flex;
		align-items: center;
		margin-left: 15px;
		width: 1043px;
	}

	.profile-tab-right .select-profile-details {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		width: 100%;
		margin-left: -11px;
	}
}
@media screen and (max-width: 1250px) {
	.filters {
		grid-template-columns: 30% 30% !important;
	}

	.ant-col-9 {
		max-width: 100% !important;
	}
	.big-collection > .ant-col {
		margin: 10px 40px;
	}
}

@media screen and (max-width: 991px) {
	.profile-tab-right {
		width: 100% !important;
		margin: auto !important;
	}
	.profile-siders {
		max-width: 100% !important;
	}
	.profile-About,
	.profile-statistic {
		width: 90% !important;
		margin: 20px auto;
	}

	.menu-twil {
		display: flex;
		flex-direction: column;
		padding: 20px;
	}
	.rowCollection {
		width: 100% !important;
		justify-content: center !important;
		margin: 10px 0;
		flex-flow: column !important;
	}
	.ant-col-9 {
		max-width: 100% !important;
	}
	.big-collection {
		display: block !important;
	}
	.ant-col {
		max-width: 100% !important;
		// flex: auto;
	}
	.collection-show-content {
		grid-template-columns: auto auto !important;
	}
	.filters {
		grid-template-columns: 30% 30% !important;
	}
}

@media screen and (min-width: 601px) and (max-width: 768px) {
	.select-profile-details {
		justify-content: space-around !important;
		flex-wrap: wrap !important;
		margin-bottom: 10px !important;
	}
	.item-image-container {
		height: auto !important;
	}
	.ant-col-9 {
		min-height: 270px !important;
		flex: auto !important;
	}
}

.mobile-tab-content-items-container {
	display: none;
}

.collection-profile-items-carousel {
	margin: 30px 0;
}

@media (max-width: 85rem) {
	.collection-profile-mobile-tab {
		display: flex;
	}

	.desktop-tab-content-items-container {
		display: none;
	}

	.profile-tab-part {
		width: 60vw;
	}

	.mobile-tab-content-items-container {
		display: block;
	}

	.checkbox-activity {
		width: 160px !important;
	}
}

@media (max-width: 75rem) {
	.profile-tab-part {
		width: 55vw;
	}
}

@media (max-width: 70rem) {
	.profile-tab-part {
		width: 50vw;
	}
}

@media (max-width: 65rem) {
	.profile-tab-part {
		width: 45vw;
	}
}

@media (max-width: 50rem) {
	.collection-show-content {
		display: flex;
		flex-direction: column;
	}
	.profile-tab-part {
		margin: 30px 0;
	}
	.profile-tab-part {
		width: 85vw;
	}
	.filters {
		grid-template-columns: auto !important;
	}
}
.collection-sider-container {
	width: 19%;
}
.satatistic-condition {
	margin-top: 40px !important;
}
.containe-self-items {
	display: flex;
}
.container-self-items-collection {
	width: 1300px;
	display: flex;
}

.img-container-items-profile {
	display: flex;
	/* width: 70%; */
	flex-wrap: wrap;
	margin-left: 50px;
}

.img-container-items-profile .ant-col.ant-col-8 {
	max-width: 400px !important;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;