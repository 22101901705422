.action-switch-container {
	width: 100%;
	border: 1px solid @normal-color;
	border-radius: 10px;
	text-align: center;
	min-height: 220px;
}
.action-switch-container-make-offre {
	width: 100%;
	border: 1px solid @normal-color;
	border-radius: 10px;
	text-align: center;
	min-height: 160px;
}

.centered-load {
	display: flex;
	align-items: center;
	justify-content: center;
}

.action-box-content {
	padding: 20px;
}

.action-box-loading {
	position: relative;
	padding: 0 !important;
}

.loading-filter {
	position: absolute;
	background-color: @light-gray-color;
	opacity: 0.8;
	border-radius: 10px;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
}

.transaction-modal-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.auction-wait-desc {
	padding: 10px 0;
}

.transaction-wait-message {
	padding: 20px 0 10px 0;
}

.transaction-wait-load {
	margin-top: 40px;
}

.small-egld-logo {
	width: 20px;
}

.auction-approximative-price {
	color: @dark-gray-color;
}

.refuse-offer-footer-buttons {
	display: grid;
	grid-template-columns: 48% 48%;
	grid-column-gap: 4%;
}

.time-picker-container {
	position: absolute;
	right: 20%;
	top: 25%;
	z-index: 2;
	box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px,
		rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

// global override
:root {
	--top-selected-color: @primary-color;
	--top-text-color: black;
	--top-colon-color: black;
	--hand-line-color: @info-color;
	--hand-circle-center: @info-color;
	--hand-circle-outer: @info-color;
	--hand-minute-circle: @primary-color;
	--meridiem-selected-bg-color: @info-color;
	--meridiem-text-color: black;
	--meridiem-selected-text-color: @primary-color;
	--clock-wrapper-bg: @medium-gray-color;
	--numbers-text-color: black;
}

.close-timepicker-button {
	cursor: pointer;
	padding: 10px 0;
	text-align: center;
}

.custom-datepicker {
	width: 100%;
	z-index: -2;
	position: absolute;
	top: 0;
	left: 0;
}

.date-picker-container {
	position: relative;
}

.real-custom-datepicker {
	z-index: 5;
}

.first-input-block {
	margin: 20px 0;
	h6 {
		padding-bottom: 10px;
	}
}
.input-block {
	margin: 10px 0;
	h6 {
		padding-bottom: 10px;
	}
}
.nft-block {
	display: flex;
	h5 {
		padding-left: 10px;
		text-transform: capitalize;
	}
}
.tiny-image-block {
	padding-top: 10px;
	margin-bottom: 20px;
	display: flex;
	h5 {
		padding-left: 10px;
		text-transform: capitalize;
		align-self: center;
	}
}
.display-flex-block {
	display: flex;
	.optional {
		padding-left: 10px;
	}
}

.converted-currency-block {
	display: flex;
	justify-content: flex-end;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;