.icon-input-container {
  position: relative;
}

.input-icon-holder {
  border-radius: @border-radius-base;
  background-color: @info-color;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.input-icon {
  width: 20px;
}

.input-faker {
  height: 50px;
  width: 100%;
  border: @border-width-base solid @input-border-color;
  border-radius: @border-radius-base;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  &:hover {
    border: @border-width-base solid @input-hover-border-color;
  }
}

.input-faker-placeholder {
  color: #bfbfbf;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;