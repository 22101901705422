.profile-photo-part-container {
  display: flex;
  margin-bottom: 40px;
}

.profile-photo-desc-part {
  margin: 0 3vw;
  width: 400px;
}

.upload-buttons-holder {
  display: flex;
  margin-top: 20px;
}

.avatar-image-container {
  width: 120px;
  height: 120px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reset-button {
  margin: 0 20px;
}

@media (max-width: 40rem) {
  .profile-photo-part-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-photo-desc-part {
    margin: 20px 0 0 0;
    width: 100%;
  }

  .upload-buttons-holder {
    flex-direction: column;
    align-items: center;
  }
  .reset-button {
    margin: 10px 0 0 0;
  }
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;