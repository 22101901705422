.collapset-faq {
	width: 1569px;
	margin: auto 8vw auto 8vw;
}

.faq-body-container {
	margin: auto 2vw auto 8vw;
	margin-bottom: 20px;
}
.collapset-faq .ant-collapse {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 18px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum", "tnum";
	background-color: rgb(251, 223, 209);
	border: 1px solid rgb(251, 223, 209);
	border-bottom: 0;
	border-radius: 15px;
}

.collapset-faq .ant-collapse-content {
	color: rgba(0, 0, 0, 0.85);
	background-color: #fff;
	border-top: 1px solid rgb(251, 223, 209);
	//
}
.collapset-faq .ant-collapse > .ant-collapse-item {
	border-bottom: 1px solid rgb(251, 223, 209);
}
.collapset-faq .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
	padding: 12px 16px;
	padding-right: 40px;
	padding-top: 25px !important;
}
.collapset-faq ul {
	list-style: none;
}
.collapset-faq li::before {
	//content:url("../../../assets/images/cercle-ul.svg");
	//font-size: 5px;
	margin-left: -1em;
	margin-top: 1em;
}
.collapset-faq-ul {
	list-style-image: url("../../../assets/images/cercle-ul.svg");
	margin-top: 15px;
}
.collapset-faq-link-ul {
	list-style-image: url("../../../assets/images/arrow-ul.svg");
	margin-top: 15px;
}
.collapset-faq li {
	font-size: 15px;
	line-height: 35px;
}

.collapset-faq .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
	padding: 12px 16px;
	padding-right: 40px;
	color: #ec6321 !important;
}

.response a {
	color: #000 !important;
}
.faq-pic {
	text-align: center;
}
.faq-pic img {
	border-radius: 10px;
}
.highlight {
	color: @primary-color;
}
.staking-page-description {
	margin-right: 6vw;
	margin-top: 20px;
}
.help-center-page-description {
	margin-right: 6vw;
	margin-top: 20px;
	li {
		font-size: 16px;
		line-height: 1.8em;
	}
}

.icon-block-help-center {
	display: flex;
	line-height: 3.2em !important;
	a {
		display: flex;
		align-items: center;
		img {
			margin-bottom: 2px;
		}
		p {
			margin-bottom: 0em;
			margin-left: 3px;
		}
		.addational-padding {
			margin-left: 7px;
		}
	}
}
.privacy-policy-page-description {
	margin-right: 6vw;
	margin-top: 20px;
}

@media (max-width: 50rem) {
	.help-center-page-description {
		li {
			font-size: 13px;
		}
	}
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;