#components-dropdown-demo-arrow .ant-btn {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 8px;
  }

  .menuNotif{
    padding: 10px ;
    max-height: 500px;
    overflow: auto;
  }
  .icon-notif {
    width: 23px;
    height: 23px;
    background-color: @info-color;
    border-radius: 50%;
    padding: 5px;
    position: absolute;
    left: 40px;
    bottom: 11px;
  }
  .icon-notif-gr {
    height: 23px;
    width: 23px;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    left: 43px;
    margin-top: 25px;
  }
  .itemNotif{
    display: flex;
    padding: 10px 5px;
    min-width: 280px;
  }
  .itemNotif:hover{
    border-radius: 10px;
    background-color: #f5f5f5; 
    cursor: pointer;
  }
  .txtNotif{
    vertical-align: middle;
    display: inline-grid;
    grid-template-rows: 0fr 0fr;
    width: 70%;
  }
  .txtNotifMsg{
    vertical-align: middle;
    display: inline-grid;
    grid-template-rows: 0fr 0fr;
    width: 90%;
  }
  .itemTime{
    font-size: 12px;
    color: #EC6321 !important;
  }

  .iconVu{
    float: right;
  }
  .notifImg{
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin-right: 10px;
    margin-left: 4px;
  }
 
  .notifImgAlert {
    background-color: @info-color;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 4px;
    padding: 13px 16px;
  }
  .notifImgAlert img{
    width: 17px;
    height: 20px;
  }
  .ant-dropdown-menu-item:hover{
    border-radius: 10px;
  }
  .ant-dropdown{
    position: fixed;
  }
  .ant-scroll-number{
    top: 5px;
    right: 5px;
    background: @primary-color;
  }


@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;