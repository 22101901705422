.profile-sider-container {
	padding: 10px 20px;
	border-radius: @border-radius-base;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	width: 100%;
	height: fit-content;
	// width:319px
}

.profile-stats-loading-container {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile-sider-container-collection {
	width: 20%;
	padding: 10px 20px;
	border-radius: @border-radius-base;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	//width: 100%;
	height: fit-content;
	//width:319px
}
.profilesider-icon > .icon-button-container {
	border-radius: 50%;
	width: 35px;
	height: 35px;
}
.profilesider-icon > .icon-button-container > .icon-button {
	height: 13px;
}
.profile-sider-row {
	display: flex;
	justify-content: space-between;
	margin: 15px 0;
}
.member-since {
	display: flex;
	justify-content: center;
}
.profil-side-about {
	display: flex;
	//justify-content: space-around;
	margin: 15px 0;
}

.collection-profile-price-holder {
	display: flex;
}
.text-regular {
	color: #ec6321;
	font-weight: bolder;
}
// .text-regular-font {
//   margin-left: 7px !important;
// }

.currency-logo {
	width: 22px;
	margin-left: 5px;
}

.collection-profile-social-links-container {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.collection-profile-social-holder {
	width: 30px;
	height: 30px;
	border-radius: 5px;
	margin: 0 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.collection-profile-social-icon {
	width: 15px;
}

.collection-profile-date-container {
	display: flex;
	justify-content: center;
	text-align: center;
	margin-bottom: 20px !important;
}
.collection-sider-container {
	width: 19%;
}

.collection-owner-block {
	display: flex;
	align-items: center;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;