.create-collection-page {
  margin: 15vh 7vw;
}
.create-collection-header {
  line-height: 1.5rem;
}

.create-collection-container {
  margin: 5% 0;
  display: flex;
  justify-content: space-between;
}

.upload-collection-picture-container {
  width: 40%;
  height: fit-content;
  margin-bottom: 80px;
}

.upload-box {
  margin: 20px 0;
  height: fit-content;
  // padding-bottom: 50px;
}

.upload-box-done {
  border: 2px solid @primary-color;
  border-radius: @border-radius-base;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 20px 0;
  height: 200px;
  padding-bottom: 50px;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  font-size: 6vw;
}
.collection-form-container {
  width: 55%;
}

.collection-slider {
  margin: 30px 0;
}

.tag-input {
  width: 10vw;
  margin: 0 10px 0 0;
}

.social-media-icon {
  width: 18px;
}

.collection-attributes-space {
  margin: 40px 0;
}

.slug-desc {
  padding: 10px 0 5px 0;
}

@media (max-width: 50rem) {
  .create-collection-container {
    flex-direction: column;
  }

  .upload-collection-picture-container {
    width: 100%;
    margin-bottom: 70px;
  }

  .upload-box {
    margin: 30px 0;
    height: 300px;
  }

  .collection-form-container {
    width: 100%;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    font-size: 10vw;
  }
}

@media (max-width: 35rem) {
  .upload-collection-picture-container {
    margin-bottom: 10vw;
  }
}

@media (max-width: 25rem) {
  .upload-collection-picture-container {
    margin-bottom: 5vw;
  }
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;