.profile-attribute-holder {
  width: 100%;
  margin: 20px 0;
}

.double-attribute-row {
  display: flex;
  justify-content: space-between;
}

.double-attribute-row-element {
  width: 47%;
}

.attribute-label {
  margin-bottom: 20px;
}

.profile-input {
  width: 100%;
  height: 50px;
  border-radius: @border-radius-base;
  border: @border-width-base solid @normal-color;
  background-color: white;
  padding: 0 10px;
  cursor: pointer;
}

.delete-part {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.copy-button {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  position: absolute;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @info-color;
  border-radius: @border-radius-base;
  cursor: pointer;
}

.copy-button-icon {
  width: 20px;
}

.erd-address-field {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background-color: @medium-gray-color;
  border-radius: @border-radius-base;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  h5 {
    margin: 0;
  }
}

.erd-address-container {
  margin-top: 20px;
}

@media (max-width: 40rem) {
  .double-attribute-row {
    display: flex;
    flex-direction: column;
  }

  .double-attribute-row-element {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 30rem) {
  .delete-part {
    display: flex;
    flex-direction: column;
  }
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;