.transparent-button-holder {
  position: relative;
  cursor: pointer;
  margin: 13px 7px;
}

.empty-button-container {
  background: rgba(172, 172, 172, 0.4) 0% 0% no-repeat padding-box;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: relative;
  padding: 10px;
  height: 40px;
  border-radius: 10px;
  display: flex;

  h6 {
    margin: 0;
  }
}

.transparent-button-icon {
  width: 15px;
  margin: 0 10px;
}

.transparent-button-text {
  color: white;
}

@primary-color: #EC6321;@info-color: #FFDFD1;@normal-color: #E8E8E8;@light-gray-color: #FAFAFA;@medium-gray-color: #F2F2F2;@dark-gray-color: #E0E0E0;@red-color: #ff4b4b;@green-color: #18a574;@border-radius-base: 15px;@border-width-base: 2px;@typography-title-margin-bottom: 0;@font-family: BoldFont;@font-size-base: 16px;@btn-font-weight: 500;@btn-padding-horizontal-base: 36px;@btn-height-base: 50px;@btn-default-color: #707070;@modal-body-padding: 40px;@input-border-color: @normal-color;@input-hover-border-color: @normal-color;@input-height-base: 50px;@input-height-lg: 50px;@shadow-color: rgba(0, 0, 0, 0.4);@table-header-bg: #ffffff;@table-font-size: 15px;